@font-face {
    font-family: "HN";
    src: url('../fonts/HelveticaNeueMed.ttf') format('truetype'); 
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

button {
	cursor: pointer;
}

input:-webkit-autofill {
　　-webkit-box-shadow: 0 0 0px 1000px white inset;
　　-webkit-text-fill-color: #333;
}


.header_dropdown .ant-dropdown-menu{
	margin-top:20px;
}

.header_dropdown .ant-dropdown-menu:before{
	content: '';
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	border-top:1px solid #aaa; 
	border-left:1px solid #aaa; 
	z-index: 2;
	position: absolute;
	left: 50%;
	top: -6px;
	margin-left: -5px;
	background: #fff;
}
.header_dropdown .ant-dropdown-menu{
	border-radius:0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	min-width: 150px;
}

.header_dropdown .ant-dropdown-menu-item{
	text-align: center;
}

.ant-select-dropdown{
	border-radius: 10px!important;
}

.locationDropdown .ant-select-dropdown-menu{
	max-height: none;
}

.teacher_popover .ant-popover-inner,.class_popover .ant-popover-inner-content{
	padding: 30px 20px 20px 30px!important;
}

.schedule_popover .ant-popover-inner-content{
	padding: 20px !important;
	text-align: center;
	font-weight: bold;
	color: inherit;
}
.schedule_popover .ant-popover-inner{
	border-radius: 10px !important;
}

.teacher_popover .ant-popover-inner-content{
	max-height: 505px;
	overflow-y: scroll;
}


.teacher_popover .ant-popover-inner-content .avator{
	width: 150px;
	height: 150px;
	border-radius: 15px;
	border: 1px solid #aaaaaa;
	overflow: hidden;
	margin: 0 auto;
}

.teacher_popover .ant-popover-inner-content .avator img{
	width: 100%;
	height: 100%;
}

.teacher_popover .ant-popover-inner-content .name{
	font-size: 20px;
	font-weight: bold;
	color: #000;
	text-align: center;
	margin: 10px 0 20px;
}

.teacher_popover .ant-popover-inner-content .description{
	line-height:1.3;
	width:368px;
	white-space:pre-wrap;
	/* max-height:300px;
	overflow-y:scroll; */
	padding-right: 10px;
}

/*滚动条的宽度*/
.teacher_popover .ant-popover-inner-content::-webkit-scrollbar,.class_popover .disc::-webkit-scrollbar {
	width:9px;
	height:9px;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
.teacher_popover .ant-popover-inner-content::-webkit-scrollbar-track,.class_popover .disc::-webkit-scrollbar-track {
	width: 6px;
	background-color:#0d1b20;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius:2em;
	display: none;
}

/*滚动条的设置*/
.teacher_popover .ant-popover-inner-content::-webkit-scrollbar-thumb,.class_popover .disc::-webkit-scrollbar-thumb {
	background-color:#D9D9D9;
	background-clip:padding-box;
	min-height:28px;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius:2em;
}

/* .class_popover, .schedule_popover{
	min-height: 400px;
} */

.class_popover .title{
	font-size: 20px;
	font-weight: bold;
	color: #000;
	text-align: center;
	margin-bottom: 10px;
}

.class_popover .attr{
	text-align: center;
}

.class_popover .disc{
	margin-top: 20px;
	width: 100%;
	line-height:1.3;
	white-space:pre-wrap;
	overflow-y:scroll;
	max-height:300px;
	padding-right: 10px;
}

.logOutConfirm .anticon-question-circle{
	display: none!important;
}

.logOutConfirm .ant-modal-confirm-title{
	text-align: center;line-height: 1!important;color: #000!important;font-size: 22px!important;
}

.logOutConfirm .ant-modal-confirm-content{
	font-size: 16px!important;
	color: #000!important;
	text-align: center;
	margin: 30px 0;
}

.logOutConfirm .ant-modal-confirm-btns{
	float: none!important;
	margin: 0 auto!important;
	text-align: center;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
}

.logOutConfirm .ant-modal-confirm-btns button{
	width: 160px;
	height: 60px;
	line-height: 60px;
	border:none;
	font-size: 20px;
	border-radius: 16px;
	background: #F2F2F2;
	color: #555;
}

.logOutConfirm .ant-modal-confirm-btns button.ant-btn-primary{
	background: #000;
	color: #fff;
}

.noticeModal .anticon-info-circle{
	display: none;
}

.noticeModal .ant-modal-confirm-title{
	text-align: center;
}

.noticeModal .ant-modal-confirm-content{
	margin-left: 0!important;
}

.noticeModal .ant-modal-confirm-btns{
	width: 100%;text-align: center;
}

.noticeModal .ant-modal-confirm-btns button{
	background: #F2F2F2;
	width: 100px;
	height: 40px;
	line-height: 40px;
	border-radius: 10px;
	border: none;
	font-weight: normal;
	color: #555;
}

.qrcode_list{
	text-align: center;
	display: flex;
	justify-content: space-between;
	line-height: 1;
	font-size: 16px;
	color: #000;
}

.qrcode_list .qrcode_item img{
	display: block;
}

.qrcode_list .qrcode_item p{
	margin-top: 12px;
	font-weight: bold;
	margin-bottom: 0;
	padding-bottom: 1em;
}

.qrcode_list .qrcode_item:first-child{
	margin-right: 20px;
}

.qrcode_popover .ant-popover-inner-content{
	padding: 24px 20px 0!important;
}

.ant-modal.waitlist .ant-modal-confirm-content{
	margin-top: 20px !important;
}
.ant-modal.customModel .btnGp{
	margin-top: 24px;
	display: flex;
	justify-content: space-around;
}
.ant-modal.customModel .btnGp button{
	border: 0
}
.ant-modal-confirm-content{
	font-size: 15px!important;
}
.ant-modal-confirm .ant-modal-confirm-btns{
	float: none!important;
	text-align: center;
}
.reverse-button.ant-modal-confirm .ant-modal-confirm-btns{
	float: none!important;
	text-align: unset;
	display: flex;
	flex-direction: row-reverse;
	gap: 8px;
	justify-content: center;
}
.reverse-button.ant-modal-confirm .ant-modal-confirm-btns button{
	flex: 1;
}
.reverse-button.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary{
	margin-left: 0;
}

.ant-modal-confirm-body{
	text-align: center;
}

.ant-modal-confirm-body > .anticon{
	float:none!important;
}
.ant-modal-confirm-body .ant-modal-confirm-title{
	display: inline!important;
}
.anticon-exclamation-circle{
	display: none!important;
}
.anticon-close-circle{
	display: none!important;
}

.anticon-question-circle{
	display: none!important;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
	/* text-align: left; */
	margin-left: 0!important;
	white-space: pre-line;
}

.ant-select-selection{
	background: #000000!important;
}
.ant-select-selection .ant-select-selection-selected-value{
	color: #ffffff;
}

.ant-select-selection .ant-select-arrow-icon{
	color: #ffffff!important;
}

.filterSelect>div{
	vertical-align: middle;
	/* flex: 1 1; */
}
.ant-select-dropdown-menu-item-selected {
	background-color: #e5e5e5 !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e5e5e5 !important;
}

.scheduleContent .ant-select-selection--multiple{
	min-height: 50px;
	margin-right: 12px;
	border-radius: 10px;
	font-size: 12px;
}

.scheduleContent .ant-select-dropdown{
	background: #000000;
}

.hide_tag .ant-select-selection__choice{
	display: none;
}

.hide{
	display: none !important;
}

.filterSelect .ant-select-selection__rendered{
	line-height: 0;
}
.ant-select-selection--multiple .ant-select-selection__rendered ul{
	min-height: 48px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.ant-select-selection--multiple .ant-select-selection__choice{
	display: inline-block;
	vertical-align: middle;
	float: none!important;
	margin: 2px 4px 2px 0!important;
	background: #000!important;
	border: none!important;
	color: #ffffff!important;

}
.hide_tag .ant-select-selection__choice{
	display: none;
}
.filterSelect .ant-select-selection--multiple .ant-select-selection__choice__remove{
	transform: none;
	-webkit-transform:none;
	top: -6px;
}
.filterSelect .ant-select-selection--multiple .ant-select-selection__choice__remove svg{
	color: #ffffff;
}
.filterSelect .select_tag_box {
	display: inline-block;
	vertical-align: middle;
	position: relative;
}
.location_tips{
	position: absolute;
	top: 50%;
	left: 15px;
	color: #ffffff;
	font-size: 16px;
	transform: translateY(-50%);
	z-index: 3;
}

.filterSelect .select_tag_box .ant-select{
	vertical-align: middle;
}
.ant-message{
	top: 50%!important;
	transform: translateY(-50%)!important;
}

.ant-message{
	/* transform: scale(2)!important; */
	zoom: 2;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
	font-size: .8rem !important;
	color: currentColor !important;
}

.ant-carousel .slick-next {
	right: 10px !important;
	width: fit-content !important;
}

.ant-carousel .slick-prev {
	left: 10px !important;
	z-index: 1;
	width: fit-content !important;
}

.slick-arrow svg {
	background: rgba(255, 255, 255, .3);
	border-radius: 6px;
	padding: 5px;
}