@import './mixins';

/*===================================
=            limit lines            =
===================================*/
// limit text to n lines
$n-lines: (1, 2, 3, 4, 5);
@each $n in $n-lines {
  .line-#{$n} {
    @include n-line($n);
  }
}
.line-inf {
  overflow: initial !important;
  white-space: normal !important;
}

/*=====  End of limit lines  ======*/

/*==============================
=            colors            =
==============================*/

@each $color, $val in $colors {
  .color-#{"" + $color} {
    color: $val !important;
  }

  .#{"" + $color}-bg {
    background-color: $val !important;
  }
}
/*=====  End of colors  ======*/

/*==================================
=            background            =
==================================*/

.background-image-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.background-image-contain {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.pb-square {
  padding-bottom: 100% !important;
}

.pb-16-9 {
  padding-bottom: 56.25% !important;
}

.pb-9-16 {
  padding-bottom: 177.78% !important;
}

.pb-3-4 {
  padding-bottom: 133.33% !important;
}

.pb-6-5 {
  padding-bottom: 120% !important;
}

.pb-16-5 {
  padding-bottom: 31.25% !important;
}

/*=====  End of background  ======*/
