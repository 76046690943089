@import "../../../scss/mixins.scss";

.teachersInfo {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 0.4rem;
    padding: 0.4rem;
    position: relative;
    z-index: 10;

    .content {
        max-height: 70vh;
        overflow-y: scroll;
        padding: 0 0.4rem;
    }

    .avatar {
        width: 4rem;
        height: 4rem;
        // @include desktopStyle {
        //     width: 9rem;
        //     height: 9rem;
        // }
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            border: 1px solid #cecece;
            border-radius: 0.2667rem;
        }
    }

    .name {
        text-align: center;
        // font-size: 0.4267rem;
        color: #000;
        margin: 0.4rem 0;
        display: block;
    }

    .disc {
        font-size: 0.3733rem;
        color: #888;
        width: 100%;
        display: block;
        white-space: pre-line;
    }

    // .locations {
    //     margin-top: 0.4rem;
    //     color: #888;

    //     .title {
    //         border-bottom: 1px solid #cecece;
    //         // font-size: 0.4rem;
    //         padding-bottom: 0.1067rem;
    //         font-weight: bold;
    //     }

    //     ul {
    //         padding-top: 0.12rem;

    //         li {
    //             list-style: inside;
    //             line-height: 1.5em;
    //         }
    //     }


    // }

    .locations {
        margin-top: 0.4rem;

        .title {
            border-bottom: 1px solid #000;
            // font-size: 0.4rem !important;
            padding-bottom: 0.1067rem;
            font-weight: bold;
            text-align: start !important;
        }

        ul {
            padding-top: 0.12rem;

            li {
                list-style: inside;
                line-height: 1.5em;
                // font-size: 0.3467rem;
            }
        }
        .title {
            font-size: 0.4rem !important;
        }

        li {
            font-size: 0.3467rem;
        }

        // @include mobileStyle {
        //     .title {
        //         font-size: 0.4rem !important;
        //     }

        //     li {
        //         font-size: 0.3467rem;
        //     }
        // }

        // @include desktopStyle {
        //     .title {
        //         font-size: 1rem !important;
        //     }

        //     li {
        //         font-size: 14px;
        //     }
        // }
    }

    .schedule {
        margin-top: 0.4rem;
        position: relative;

        .title {
            border-bottom: 1px solid #000;
            font-size: 0.4rem !important;
            padding-bottom: 0.1067rem;
            font-weight: bold;
            text-align: start !important;
        }

        .filter {
            display: flex;
            position: absolute;
            top: 0.1rem;
            right: 0;
            width: 60%;
            text-align: center;
            justify-content: flex-end;

            .filterTitle {
                color: black;
                font-weight: bold;
                font-size: .33rem;
            }
        }

        li {
            border-bottom: 1px solid #cecece;
            padding: 0.2rem 0;

            .date {
                font-size: .32rem;
                color: black;
            }

            .class_info {
                h4 {
                    font-size: .4rem;
                    font-weight: 700;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin: .1rem 0;
                }

                .time-name {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    .class-name {
                        flex: 1;
                        -webkit-line-clamp: 2;
                        height: fit-content;
                        -webkit-line-clamp: 2 !important;
                        -webkit-box-orient: vertical !important;
                        display: -webkit-box !important;
                        white-space: normal !important;
                    }
                }

                .down {
                    width: 100%;
                    height: 1.1rem;
                    display: flex;
                    justify-content: space-between;

                    .opera {
                        right: 0;
                        position: absolute;
                        margin-top: 0;
                        justify-content: center;
                    }

                    .left {
                        width: calc(100% - 2.3rem);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .leftInfo {
                            display: flex;

                            .infoContent {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            .teacherName {
                                // max-width: 50%;
                                width: fit-content;
                            }

                            .timeContent {
                                white-space: nowrap;
                                // width: 1.1rem;
                            }

                            .mood {
                                display: flex;

                                // max-width: 25%;
                                .moodName {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }

            }

            .iconfont {
                font-size: .3rem;
            }
        }

        // @include mobileStyle {
        //     .title {
        //         font-size: 0.4rem !important;
        //     }
        //     .filterTitle {
        //         font-size: .33rem;
        //     }

        //     li {
        //         font-size: 0.3467rem;
        //     }
        // }

        // @include desktopStyle {
        //     .title {
        //         font-size: 1rem !important;
        //     }
        //     .filterTitle {
        //         font-size: .33rem;
        //     }
        //     li {
        //         font-size: 14px;
        //     }
        // }
    }

    .buttonSection {
        display: flex;

        button {
            flex: 1 1;
            margin: 0.3em;
            border-radius: 10px;
            background: #fff;
            border: solid #d8d8d8 1px;
            color: #000;
            padding: 8px;
        }
    }

    .opera {
        text-align: center;
        margin-top: 0.4rem;

        .close {
            background: #f2f2f2;
            color: #888;
            border-radius: 10px;
            // font-size: 0.3733rem;
            padding: 8px 15px;
            min-width: 2rem;
            border: 0;
        }
    }
}