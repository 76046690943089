.myCancellations{ 
    background: #fff;
    height: 100%;
    .nav{
        margin:0;
        position: sticky;
        background: white;
        z-index: 6;
        top: 0px;
    }
    .back{
        width: auto;
        height: 1.28rem;
        width: 1rem;
        text-align: center;
        position: absolute;
        left: 0px;
        background: none;
        z-index: 3;
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 1.28rem;
        line-height: 1.28rem;
        margin-bottom: 0;
        border-bottom: 1px solid #ddd;

        li{
            a{
            line-height: 1.28rem;
            display: inline-block;
            position: relative;
            color: #bbb;
            transition: 0.5s;
            padding: 0 0.2667rem;
            font-size: 0.3467rem;

                &:after{
                    content: '';
                    width: 100%;
                    height: 0.1333rem;
                    background: #404040;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    transition: 0.5s;
                    opacity: 0;
                }
            }
            .active{
                color: #000;
                &:after{opacity: 1;}
            }
        }

    }
    .scroll_list{
        height: 100%; 
        overflow-y: scroll;
    }
    .filter{
        height: 1.5333rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        .date{
            font-size: 0.4rem;
            .iconfont{
                font-size: 0.32rem;
                margin-left: 0.1333rem;
                color: #b9b9b9;
            }
        }
        .order{
            position: absolute;
            right: 0.2667rem;
            button{
                width: 0.8rem;
                height: 1.5333rem;
                background: none;
                color: #888;
            }
        }
    }
    .list{
        .item{
            .date{
                color: #000;
                background: #f2f2f2;
                padding: 0 0.4rem;
                height: 0.8rem;
                line-height: 0.8rem;
                font-weight: normal;
                font-size:.32rem;
            }
            .class_detail_wrapper {
                position: relative;
                .class_detail_overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
            li{
                .divOverlay {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
    }
}