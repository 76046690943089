.schedule-filter-pop-wrapper {
  position: relative;
  z-index: 99;
}
.schedule-filter-pop-wrapper .title {
  font-size: 0.4267rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  text-align: center;
}
.schedule-filter-pop-wrapper .pop_content {
  background: #fff;
  overflow: hidden;
  border-radius: 0.2667rem;
  margin: 0.5333rem auto;
  transition: 0.5s;
  width: 90%;
}
.schedule-filter-pop-wrapper .pop_content .panel {
  height: 1.5rem;
  border-bottom: 1px solid #dedede;
  padding: 0 0.4rem;
  position: relative;
  display: flex;
  align-items: center;
}
.schedule-filter-pop-wrapper .pop_content .panel .name {
  font-weight: bold;
  font-size: 0.4rem;
  color: #000;
  margin-right: 0.2rem;
}
.schedule-filter-pop-wrapper .pop_content .panel .active {
  color: #333;
  font-size: 0.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  max-width: calc(100% - 2.6667rem);
  display: block;
}
.schedule-filter-pop-wrapper .pop_content .panel .iconfont {
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.4267rem;
  color: #888;
}
.schedule-filter-pop-wrapper .pop_content .panel:last-child {
  border-bottom: none;
}
.schedule-filter-pop-wrapper .pop_content .opera {
  margin-top: 0.667rem;
  padding-bottom: 0.6667rem;
  display: flex;
  justify-content: space-around;
}
.schedule-filter-pop-wrapper .pop_content .opera button {
  width: 2.8rem;
  height: 1.08rem;
  border-radius: 0.2667rem;
  font-size: 0.3733rem;
}
.schedule-filter-pop-wrapper .pop_content .opera button.reset {
  background: #f2f2f2;
  color: #808080;
}
.schedule-filter-pop-wrapper .pop_content .opera button.apply {
  background: #000;
  color: #fff;
}
.schedule-filter-pop-wrapper .pop_content .select_title {
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  position: relative;
  color: #737373;
  background: #f2f2f2;
  font-size: 0.3733rem;
}
.schedule-filter-pop-wrapper .pop_content .select_title .back {
  width: 0.96rem;
  height: 0.96rem;
  background: none;
  position: absolute;
  left: 0;
  top: 0;
}
.schedule-filter-pop-wrapper .pop_content .select_title .back .iconfont {
  font-size: 0.4533rem;
  color: #777;
}
.schedule-filter-pop-wrapper .pop_content .list {
  max-height: 7.2rem;
  overflow-y: scroll;
}
.schedule-filter-pop-wrapper .pop_content .list li {
  height: 1.2rem;
  line-height: 1.2rem;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0.4rem;
  position: relative;
  font-size: 0.4rem;
  color: #333;
  padding-right: 1.2rem;
}
.schedule-filter-pop-wrapper .pop_content .list li span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.3733rem;
}
.schedule-filter-pop-wrapper .pop_content .list li:last-child {
  border-bottom: none;
}
.schedule-filter-pop-wrapper .pop_content .list li .iconfont {
  color: #777;
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
}
.schedule-filter-pop-wrapper .opera {
  text-align: center;
}
.schedule-filter-pop-wrapper .opera button {
  width: 2.6667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 0.16rem;
  color: #888;
  font-size: 0.3733rem;
  background: #fff;
}
