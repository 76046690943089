.Schedulefilter {
  padding: 0 0.2667rem;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 #f2f2f2;
  font-size: 0.3733rem;
  flex-direction: column;
}
.Schedulefilter .sector {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
}
.Schedulefilter .sector .item {
  height: 0.9rem;
  line-height: 0.9rem;
  position: relative;
  width: 2.8rem;
  margin: 0 0.1333rem;
  transition: 0.2s;
  text-align: center;
  color: #ababab;
  border: solid #d8d8d8 1px;
  border-radius: 12px;
  flex: 50%;
}
.Schedulefilter .sector .item.yoga.active {
  color: #fff;
  background: #14E1DC;
}
.Schedulefilter .sector .item.yoga.active:after {
  opacity: 1;
}
.Schedulefilter .sector .item.fitness.active {
  color: #fff;
  background: #F00F64;
}
.Schedulefilter .sector .item.fitness.active:after {
  opacity: 1;
}
.Schedulefilter .sector .item.mood {
  background-size: 0.24rem !important;
  background-position: 94% !important;
  background-repeat: no-repeat !important;
  padding: 0 10px;
}
.Schedulefilter .sector .item.mood p {
  text-overflow: ellipsis;
  width: 85%;
  overflow: hidden;
  white-space: nowrap;
}
.Schedulefilter .filter_btn {
  width: 1.3333rem;
  height: 1rem;
  background: none;
  color: #ababab;
  font-weight: 700;
  margin-left: 7px;
  font-size: 0.35rem;
}
.Schedulefilter .locate {
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 8px 0px;
  margin: auto;
}
.Schedulefilter .locate .location {
  flex: 1;
}
.Schedulefilter .locate .location .active_location {
  flex: 1;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 0.2rem;
  background: #f2f2f2 url(./img/arrow.png) no-repeat 96% center;
  background-size: 0.24rem;
  text-align: left;
  color: #ababab;
  padding: 0 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
