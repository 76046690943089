@import url(https://at.alicdn.com/t/font_1248451_9cxfb7bfmn.css);
@font-face {
    font-family: "HN";
    src: url(/static/media/HelveticaNeueMed.9f25b1f8.ttf) format('truetype'); 
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

button {
	cursor: pointer;
}

input:-webkit-autofill {
　　-webkit-box-shadow: 0 0 0px 1000px white inset;
　　-webkit-text-fill-color: #333;
}


.header_dropdown .ant-dropdown-menu{
	margin-top:20px;
}

.header_dropdown .ant-dropdown-menu:before{
	content: '';
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	border-top:1px solid #aaa; 
	border-left:1px solid #aaa; 
	z-index: 2;
	position: absolute;
	left: 50%;
	top: -6px;
	margin-left: -5px;
	background: #fff;
}
.header_dropdown .ant-dropdown-menu{
	border-radius:0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	min-width: 150px;
}

.header_dropdown .ant-dropdown-menu-item{
	text-align: center;
}

.ant-select-dropdown{
	border-radius: 10px!important;
}

.locationDropdown .ant-select-dropdown-menu{
	max-height: none;
}

.teacher_popover .ant-popover-inner,.class_popover .ant-popover-inner-content{
	padding: 30px 20px 20px 30px!important;
}

.schedule_popover .ant-popover-inner-content{
	padding: 20px !important;
	text-align: center;
	font-weight: bold;
	color: inherit;
}
.schedule_popover .ant-popover-inner{
	border-radius: 10px !important;
}

.teacher_popover .ant-popover-inner-content{
	max-height: 505px;
	overflow-y: scroll;
}


.teacher_popover .ant-popover-inner-content .avator{
	width: 150px;
	height: 150px;
	border-radius: 15px;
	border: 1px solid #aaaaaa;
	overflow: hidden;
	margin: 0 auto;
}

.teacher_popover .ant-popover-inner-content .avator img{
	width: 100%;
	height: 100%;
}

.teacher_popover .ant-popover-inner-content .name{
	font-size: 20px;
	font-weight: bold;
	color: #000;
	text-align: center;
	margin: 10px 0 20px;
}

.teacher_popover .ant-popover-inner-content .description{
	line-height:1.3;
	width:368px;
	white-space:pre-wrap;
	/* max-height:300px;
	overflow-y:scroll; */
	padding-right: 10px;
}

/*滚动条的宽度*/
.teacher_popover .ant-popover-inner-content::-webkit-scrollbar,.class_popover .disc::-webkit-scrollbar {
	width:9px;
	height:9px;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
.teacher_popover .ant-popover-inner-content::-webkit-scrollbar-track,.class_popover .disc::-webkit-scrollbar-track {
	width: 6px;
	background-color:#0d1b20;
	border-radius:2em;
	display: none;
}

/*滚动条的设置*/
.teacher_popover .ant-popover-inner-content::-webkit-scrollbar-thumb,.class_popover .disc::-webkit-scrollbar-thumb {
	background-color:#D9D9D9;
	background-clip:padding-box;
	min-height:28px;
	border-radius:2em;
}

/* .class_popover, .schedule_popover{
	min-height: 400px;
} */

.class_popover .title{
	font-size: 20px;
	font-weight: bold;
	color: #000;
	text-align: center;
	margin-bottom: 10px;
}

.class_popover .attr{
	text-align: center;
}

.class_popover .disc{
	margin-top: 20px;
	width: 100%;
	line-height:1.3;
	white-space:pre-wrap;
	overflow-y:scroll;
	max-height:300px;
	padding-right: 10px;
}

.logOutConfirm .anticon-question-circle{
	display: none!important;
}

.logOutConfirm .ant-modal-confirm-title{
	text-align: center;line-height: 1!important;color: #000!important;font-size: 22px!important;
}

.logOutConfirm .ant-modal-confirm-content{
	font-size: 16px!important;
	color: #000!important;
	text-align: center;
	margin: 30px 0;
}

.logOutConfirm .ant-modal-confirm-btns{
	float: none!important;
	margin: 0 auto!important;
	text-align: center;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
}

.logOutConfirm .ant-modal-confirm-btns button{
	width: 160px;
	height: 60px;
	line-height: 60px;
	border:none;
	font-size: 20px;
	border-radius: 16px;
	background: #F2F2F2;
	color: #555;
}

.logOutConfirm .ant-modal-confirm-btns button.ant-btn-primary{
	background: #000;
	color: #fff;
}

.noticeModal .anticon-info-circle{
	display: none;
}

.noticeModal .ant-modal-confirm-title{
	text-align: center;
}

.noticeModal .ant-modal-confirm-content{
	margin-left: 0!important;
}

.noticeModal .ant-modal-confirm-btns{
	width: 100%;text-align: center;
}

.noticeModal .ant-modal-confirm-btns button{
	background: #F2F2F2;
	width: 100px;
	height: 40px;
	line-height: 40px;
	border-radius: 10px;
	border: none;
	font-weight: normal;
	color: #555;
}

.qrcode_list{
	text-align: center;
	display: flex;
	justify-content: space-between;
	line-height: 1;
	font-size: 16px;
	color: #000;
}

.qrcode_list .qrcode_item img{
	display: block;
}

.qrcode_list .qrcode_item p{
	margin-top: 12px;
	font-weight: bold;
	margin-bottom: 0;
	padding-bottom: 1em;
}

.qrcode_list .qrcode_item:first-child{
	margin-right: 20px;
}

.qrcode_popover .ant-popover-inner-content{
	padding: 24px 20px 0!important;
}

.ant-modal.waitlist .ant-modal-confirm-content{
	margin-top: 20px !important;
}
.ant-modal.customModel .btnGp{
	margin-top: 24px;
	display: flex;
	justify-content: space-around;
}
.ant-modal.customModel .btnGp button{
	border: 0
}
.ant-modal-confirm-content{
	font-size: 15px!important;
}
.ant-modal-confirm .ant-modal-confirm-btns{
	float: none!important;
	text-align: center;
}
.reverse-button.ant-modal-confirm .ant-modal-confirm-btns{
	float: none!important;
	text-align: unset;
	display: flex;
	flex-direction: row-reverse;
	grid-gap: 8px;
	gap: 8px;
	justify-content: center;
}
.reverse-button.ant-modal-confirm .ant-modal-confirm-btns button{
	flex: 1 1;
}
.reverse-button.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary{
	margin-left: 0;
}

.ant-modal-confirm-body{
	text-align: center;
}

.ant-modal-confirm-body > .anticon{
	float:none!important;
}
.ant-modal-confirm-body .ant-modal-confirm-title{
	display: inline!important;
}
.anticon-exclamation-circle{
	display: none!important;
}
.anticon-close-circle{
	display: none!important;
}

.anticon-question-circle{
	display: none!important;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
	/* text-align: left; */
	margin-left: 0!important;
	white-space: pre-line;
}

.ant-select-selection{
	background: #000000!important;
}
.ant-select-selection .ant-select-selection-selected-value{
	color: #ffffff;
}

.ant-select-selection .ant-select-arrow-icon{
	color: #ffffff!important;
}

.filterSelect>div{
	vertical-align: middle;
	/* flex: 1 1; */
}
.ant-select-dropdown-menu-item-selected {
	background-color: #e5e5e5 !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e5e5e5 !important;
}

.scheduleContent .ant-select-selection--multiple{
	min-height: 50px;
	margin-right: 12px;
	border-radius: 10px;
	font-size: 12px;
}

.scheduleContent .ant-select-dropdown{
	background: #000000;
}

.hide_tag .ant-select-selection__choice{
	display: none;
}

.hide{
	display: none !important;
}

.filterSelect .ant-select-selection__rendered{
	line-height: 0;
}
.ant-select-selection--multiple .ant-select-selection__rendered ul{
	min-height: 48px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.ant-select-selection--multiple .ant-select-selection__choice{
	display: inline-block;
	vertical-align: middle;
	float: none!important;
	margin: 2px 4px 2px 0!important;
	background: #000!important;
	border: none!important;
	color: #ffffff!important;

}
.hide_tag .ant-select-selection__choice{
	display: none;
}
.filterSelect .ant-select-selection--multiple .ant-select-selection__choice__remove{
	transform: none;
	-webkit-transform:none;
	top: -6px;
}
.filterSelect .ant-select-selection--multiple .ant-select-selection__choice__remove svg{
	color: #ffffff;
}
.filterSelect .select_tag_box {
	display: inline-block;
	vertical-align: middle;
	position: relative;
}
.location_tips{
	position: absolute;
	top: 50%;
	left: 15px;
	color: #ffffff;
	font-size: 16px;
	transform: translateY(-50%);
	z-index: 3;
}

.filterSelect .select_tag_box .ant-select{
	vertical-align: middle;
}
.ant-message{
	top: 50%!important;
	transform: translateY(-50%)!important;
}

.ant-message{
	/* transform: scale(2)!important; */
	zoom: 2;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
	font-size: .8rem !important;
	color: currentColor !important;
}

.ant-carousel .slick-next {
	right: 10px !important;
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}

.ant-carousel .slick-prev {
	left: 10px !important;
	z-index: 1;
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}

.slick-arrow svg {
	background: rgba(255, 255, 255, .3);
	border-radius: 6px;
	padding: 5px;
}

.htmlContentWrapper img {
  width: 100%; }

.loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translateZ(1px);
  z-index: 2000;
}
.loading-wrapper.hide {
  display: none;
}
.loading-wrapper.loading-mobile .ant-spin-text {
  color: rgba(0, 0, 0, 0.65) !important;
}
.loading-wrapper .loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#root .is-mobile .schedule_button_wrapper .bookBtn {
  border: none; }

#root .is-mobile .schedule_button_wrapper .btn {
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.3rem;
  border-radius: 0.1333rem;
  text-align: center;
  transition: 0.5s;
  padding: 0;
  font-weight: 700;
  color: #888888; }
  #root .is-mobile .schedule_button_wrapper .btn.btn1, #root .is-mobile .schedule_button_wrapper .btn.btn3, #root .is-mobile .schedule_button_wrapper .btn.btn17 {
    border-color: #aaa;
    background: #F0EFF5; }
    #root .is-mobile .schedule_button_wrapper .btn.btn1.Y.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn3.Y.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn17.Y.confirm {
      background: #C4E7FF; }
    #root .is-mobile .schedule_button_wrapper .btn.btn1.F.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn3.F.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn17.F.confirm {
      background: #FFCED0; }
  #root .is-mobile .schedule_button_wrapper .btn.btn2, #root .is-mobile .schedule_button_wrapper .btn.btn15, #root .is-mobile .schedule_button_wrapper .btn.btn14 {
    color: #fff; }
    #root .is-mobile .schedule_button_wrapper .btn.btn2.Y, #root .is-mobile .schedule_button_wrapper .btn.btn15.Y, #root .is-mobile .schedule_button_wrapper .btn.btn14.Y {
      background: #14E1DC;
      border-color: #14E1DC; }
      #root .is-mobile .schedule_button_wrapper .btn.btn2.Y.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn15.Y.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn14.Y.confirm {
        background: #000000; }
    #root .is-mobile .schedule_button_wrapper .btn.btn2.F, #root .is-mobile .schedule_button_wrapper .btn.btn15.F, #root .is-mobile .schedule_button_wrapper .btn.btn14.F {
      background: #F00F64;
      border-color: #F00F64; }
      #root .is-mobile .schedule_button_wrapper .btn.btn2.F.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn15.F.confirm, #root .is-mobile .schedule_button_wrapper .btn.btn14.F.confirm {
        background: #000000; }
  #root .is-mobile .schedule_button_wrapper .btn.btn4 {
    color: #fff;
    background: #000; }
  #root .is-mobile .schedule_button_wrapper .btn.btn5, #root .is-mobile .schedule_button_wrapper .btn.btn12 {
    color: #fff;
    background: #BFBFBF; }
  #root .is-mobile .schedule_button_wrapper .btn.btn13, #root .is-mobile .schedule_button_wrapper .btn.btn8, #root .is-mobile .schedule_button_wrapper .btn.btn11 {
    background: #EFEFF4; }
  #root .is-mobile .schedule_button_wrapper .btn.btn7, #root .is-mobile .schedule_button_wrapper .btn.btn9 {
    background-color: #EFEFEF; }
  #root .is-mobile .schedule_button_wrapper .btn.btn16 {
    background: #EFEFF4;
    white-space: unset !important;
    padding: 0 15px;
    line-height: 0.3rem !important; }

#root .is-mobile .schedule_button_wrapper.black .btn {
  font-weight: 700;
  color: #888888; }
  #root .is-mobile .schedule_button_wrapper.black .btn.btn1, #root .is-mobile .schedule_button_wrapper.black .btn.btn3, #root .is-mobile .schedule_button_wrapper.black .btn.btn17 {
    color: #888888;
    background: rgba(255, 255, 255, 0.76); }
    #root .is-mobile .schedule_button_wrapper.black .btn.btn1.Y.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn3.Y.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn17.Y.confirm {
      background: #59290B;
      border-color: #59290B;
      color: #E06B26; }
    #root .is-mobile .schedule_button_wrapper.black .btn.btn1.F.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn3.F.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn17.F.confirm {
      background: #59290B;
      border-color: #59290B;
      color: #E06B26; }
  #root .is-mobile .schedule_button_wrapper.black .btn.btn2, #root .is-mobile .schedule_button_wrapper.black .btn.btn15, #root .is-mobile .schedule_button_wrapper.black .btn.btn14 {
    color: #fff;
    background: #D17239; }
    #root .is-mobile .schedule_button_wrapper.black .btn.btn2.Y, #root .is-mobile .schedule_button_wrapper.black .btn.btn15.Y, #root .is-mobile .schedule_button_wrapper.black .btn.btn14.Y {
      background: #D17239;
      border-color: #D17239; }
      #root .is-mobile .schedule_button_wrapper.black .btn.btn2.Y.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn15.Y.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn14.Y.confirm {
        background: #59290B;
        border-color: #59290B;
        color: #E06B26; }
    #root .is-mobile .schedule_button_wrapper.black .btn.btn2.F, #root .is-mobile .schedule_button_wrapper.black .btn.btn15.F, #root .is-mobile .schedule_button_wrapper.black .btn.btn14.F {
      background: #D17239;
      border-color: #D17239; }
      #root .is-mobile .schedule_button_wrapper.black .btn.btn2.F.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn15.F.confirm, #root .is-mobile .schedule_button_wrapper.black .btn.btn14.F.confirm {
        background: #59290B;
        border-color: #59290B;
        color: #E06B26; }
  #root .is-mobile .schedule_button_wrapper.black .btn.btn5, #root .is-mobile .schedule_button_wrapper.black .btn.btn9, #root .is-mobile .schedule_button_wrapper.black .btn.btn12 {
    background: rgba(255, 255, 255, 0.5);
    color: #fff; }
  #root .is-mobile .schedule_button_wrapper.black .btn.btn13, #root .is-mobile .schedule_button_wrapper.black .btn.btn8, #root .is-mobile .schedule_button_wrapper.black .btn.btn11 {
    background: rgba(255, 255, 255, 0.76); }
  #root .is-mobile .schedule_button_wrapper.black .btn.btn4 {
    background: #D17239;
    color: #fff; }
  #root .is-mobile .schedule_button_wrapper.black .btn.btn7 {
    background-color: #EFEFEF; }
  #root .is-mobile .schedule_button_wrapper.black .btn.btn16 {
    background: rgba(255, 255, 255, 0.76);
    white-space: unset !important;
    padding: 0 15px;
    line-height: 0.3rem !important; }

#root .is-desktop .schedule_button_wrapper .bookBtn {
  min-width: 78px; }

#root .is-desktop .schedule_button_wrapper .btn {
  border: 0; }

#root .is-desktop .schedule_button_wrapper .status2, #root .is-desktop .schedule_button_wrapper .status15, #root .is-desktop .schedule_button_wrapper .status14 {
  color: #fff; }
  #root .is-desktop .schedule_button_wrapper .status2.Y, #root .is-desktop .schedule_button_wrapper .status15.Y, #root .is-desktop .schedule_button_wrapper .status14.Y {
    background: #14E1DC;
    border-color: #14E1DC; }
    #root .is-desktop .schedule_button_wrapper .status2.Y.confirm, #root .is-desktop .schedule_button_wrapper .status15.Y.confirm, #root .is-desktop .schedule_button_wrapper .status14.Y.confirm {
      background: #000000; }
      #root .is-desktop .schedule_button_wrapper .status2.Y.confirm:hover, #root .is-desktop .schedule_button_wrapper .status15.Y.confirm:hover, #root .is-desktop .schedule_button_wrapper .status14.Y.confirm:hover {
        color: #fff; }
  #root .is-desktop .schedule_button_wrapper .status2.F, #root .is-desktop .schedule_button_wrapper .status15.F, #root .is-desktop .schedule_button_wrapper .status14.F {
    background: #F00F64;
    border-color: #F00F64; }
    #root .is-desktop .schedule_button_wrapper .status2.F.confirm, #root .is-desktop .schedule_button_wrapper .status15.F.confirm, #root .is-desktop .schedule_button_wrapper .status14.F.confirm {
      background: #000000; }
      #root .is-desktop .schedule_button_wrapper .status2.F.confirm:hover, #root .is-desktop .schedule_button_wrapper .status15.F.confirm:hover, #root .is-desktop .schedule_button_wrapper .status14.F.confirm:hover {
        color: #fff; }

#root .is-desktop .schedule_button_wrapper .status1, #root .is-desktop .schedule_button_wrapper .status3, #root .is-desktop .schedule_button_wrapper .status17 {
  border-color: #aaa;
  background: #F0EFF5; }
  #root .is-desktop .schedule_button_wrapper .status1.Y.confirm, #root .is-desktop .schedule_button_wrapper .status3.Y.confirm, #root .is-desktop .schedule_button_wrapper .status17.Y.confirm {
    background: #C4E7FF; }
  #root .is-desktop .schedule_button_wrapper .status1.F.confirm, #root .is-desktop .schedule_button_wrapper .status3.F.confirm, #root .is-desktop .schedule_button_wrapper .status17.F.confirm {
    background: #FFCED0; }

#root .is-desktop .schedule_button_wrapper .status4 {
  color: #fff;
  background: #000; }

#root .is-desktop .schedule_button_wrapper .status5 {
  color: #fff;
  background: #BFBFBF; }

#root .is-desktop .schedule_button_wrapper .status13, #root .is-desktop .schedule_button_wrapper .status8, #root .is-desktop .schedule_button_wrapper .status11, #root .is-desktop .schedule_button_wrapper .status16 {
  background: #EFEFF4; }

#root .is-desktop .schedule_button_wrapper .status7, #root .is-desktop .schedule_button_wrapper .status9 {
  background-color: #EFEFEF;
  color: #000; }

.schedule-mobile {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .schedule-mobile .scheduleList {
    position: relative;
    overflow: scroll;
    height: 100%;
    z-index: 1; }
    .schedule-mobile .scheduleList .item .date {
      color: #000;
      background: #f2f2f2;
      padding: 0 0.4rem;
      height: 0.8rem;
      line-height: 0.8rem;
      font-weight: normal;
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      z-index: 1;
      font-size: .32rem; }
    .schedule-mobile .scheduleList .item ul {
      margin: 0; }
    .schedule-mobile .scheduleList .fixedDate {
      color: #000;
      background: #f2f2f2;
      padding: 0 0.4rem;
      height: calc(0.8rem + 1px);
      line-height: 0.8rem;
      font-weight: normal;
      margin: 0;
      position: fixed;
      width: 100%;
      z-index: 3;
      margin-top: -1px; }
      .schedule-mobile .scheduleList .fixedDate.absolute {
        position: absolute;
        top: 0; }
    .schedule-mobile .scheduleList .no-class {
      display: flex;
      min-height: 150px;
      justify-content: center;
      background-color: white;
      text-align: center; }
  .schedule-mobile .fixed {
    padding-top: 1.81rem; }
    .schedule-mobile .fixed .date_list {
      position: fixed;
      width: 100%;
      top: 1.2rem;
      z-index: 3; }

.notice {
  padding: 0.2rem 0.2rem 0; }
  .notice .title {
    height: 0.88rem;
    line-height: 0.88rem;
    padding: 0 0.08rem 0 0.5333rem;
    background: #c00000;
    color: #fff;
    font-weight: bold;
    font-size: 0.4rem;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    overflow: hidden; }
    .notice .title span {
      height: 0.88rem;
      width: 0.88rem;
      float: right;
      text-align: center; }
      .notice .title span .iconfont {
        font-size: 0.2667rem; }
  .notice .notice_wra {
    padding: 0.32rem 0.32rem 0.4rem 0.5333rem;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    background: #ffebeb;
    position: relative;
    font-size: 0.3733rem;
    color: #333; }
    .notice .notice_wra .line {
      width: 0.1333rem;
      height: 1.7333rem;
      background: #c00000;
      position: absolute;
      left: 0.2rem;
      top: 0.32rem; }
    .notice .notice_wra .headLine {
      color: #000;
      font-weight: bold;
      font-size: 0.4rem;
      margin-bottom: 0.1333rem; }
    .notice .notice_wra .words {
      font-size: 0.32rem;
      word-break: break-word; }
    .notice .notice_wra .opera {
      text-align: right;
      margin-top: 0.2rem; }
      .notice .notice_wra .opera button {
        width: 2rem;
        height: 0.88rem;
        background: #c00;
        color: #fff;
        border-radius: 0.2rem;
        font-size: 0.3733rem; }

.class_list_wrapper {
  height: 2.16rem;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0.22rem 0.4267rem 0.22rem 0.4267rem; }
  .class_list_wrapper.black {
    background-color: #434343; }
    .class_list_wrapper.black .class_info .classTitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      grid-gap: 5px;
      gap: 5px; }
      .class_list_wrapper.black .class_info .classTitle h4 {
        color: #fff;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.4rem;
        line-height: 1;
        max-width: 100%; }
      .class_list_wrapper.black .class_info .classTitle .class-name {
        flex: 1 1;
        -webkit-line-clamp: 2;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
        display: -webkit-box !important;
        white-space: normal !important; }
      .class_list_wrapper.black .class_info .classTitle .time {
        float: right;
        margin-left: 0.1rem; }
    .class_list_wrapper.black .class_info img {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 0;
      top: 0; }
    .class_list_wrapper.black .class_info .down p {
      color: #fff; }
  .class_list_wrapper .avatarSvg {
    width: 1.4667rem;
    height: 1.4667rem; }
  .class_list_wrapper .avatar {
    width: 1.4667rem;
    height: 1.4667rem;
    transform: scale(1.2); }
    .class_list_wrapper .avatar img {
      width: 100%;
      height: 100%; }
    .class_list_wrapper .avatar .vaccinated {
      position: absolute;
      left: 0.75rem;
      top: 1rem;
      width: 0.5rem;
      height: 0.5rem; }
  .class_list_wrapper .line {
    width: 0.16rem;
    height: 1.4667rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  .class_list_wrapper .class_info {
    width: calc(100% - 1.4667rem);
    padding: 0 0 0 0.2667rem;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 5px;
    gap: 5px; }
    .class_list_wrapper .class_info .classTitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      grid-gap: 5px;
      gap: 5px; }
      .class_list_wrapper .class_info .classTitle h4 {
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
        color: #000;
        font-size: 0.4rem;
        line-height: 1;
        max-width: 100%; }
      .class_list_wrapper .class_info .classTitle .class-name {
        flex: 1 1;
        -webkit-line-clamp: 2;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
        display: -webkit-box !important;
        white-space: normal !important; }
      .class_list_wrapper .class_info .classTitle .time {
        float: right;
        margin-left: 0.1rem; }
    .class_list_wrapper .class_info .down {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .class_list_wrapper .class_info .down .left {
        width: calc(100% - 2.3rem);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        grid-gap: 5px;
        gap: 5px; }
        .class_list_wrapper .class_info .down .left .leftInfo {
          display: flex; }
          .class_list_wrapper .class_info .down .left .leftInfo .infoContent {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; }
          .class_list_wrapper .class_info .down .left .leftInfo .teacherName {
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content; }
          .class_list_wrapper .class_info .down .left .leftInfo .timeContent {
            white-space: nowrap; }
          .class_list_wrapper .class_info .down .left .leftInfo .mood {
            display: flex; }
            .class_list_wrapper .class_info .down .left .leftInfo .mood .moodName {
              font-weight: bold; }
      .class_list_wrapper .class_info .down p {
        color: #888;
        font-size: 0.32rem;
        margin-bottom: 0rem; }
        .class_list_wrapper .class_info .down p .iconfont {
          font-size: 0.24rem; }
      .class_list_wrapper .class_info .down .opera {
        width: 2.04rem;
        display: flex;
        flex-direction: column;
        justify-content: center; }

.date_list {
  height: 1.81rem;
  background: #fff;
  z-index: 2;
  box-shadow: 0 1px 0 0 #f2f2f2;
  position: relative;
  padding: 0 0.6rem 0 0.6rem;
}
.date_list.pr .weekToggle {
  right: 0rem;
  justify-content: flex-start;
  border: 0px;
}
.date_list.pl .weekToggle {
  left: 0rem;
  justify-content: center;
  border: 0px;
}
.date_list .list {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  text-align: center;
}
.date_list .list a {
  height: 1.81rem;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #999;
  position: relative;
}
.date_list .list a::after {
  content: '';
  width: 1.0667rem;
  height: 0.2133rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s;
}
.date_list .list a .week {
  font-size: 0.266667rem;
}
.date_list .list a .num {
  font-size: 0.6rem;
  font-weight: bold;
}
.date_list .list a.active::after {
  background: #404040;
}
.date_list .list a.active .num {
  font-weight: bold;
  color: #000;
}
.date_list .weekToggle {
  width: 0.666667rem;
  height: 1.81rem;
  position: absolute;
  top: 0;
  background: none;
  outline: none;
  color: #333;
  z-index: 2;
  transition: 0.5s;
  transform-origin: center center;
  display: flex;
  align-items: center;
}
.date_list .weekToggle .iconfont {
  font-size: 0.4rem;
}
.date_list .weekToggle.rotate {
  transform: rotate(-180deg);
}

.Schedulefilter {
  padding: 0 0.2667rem;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 #f2f2f2;
  font-size: 0.3733rem;
  flex-direction: column;
}
.Schedulefilter .sector {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
}
.Schedulefilter .sector .item {
  height: 0.9rem;
  line-height: 0.9rem;
  position: relative;
  width: 2.8rem;
  margin: 0 0.1333rem;
  transition: 0.2s;
  text-align: center;
  color: #ababab;
  border: solid #d8d8d8 1px;
  border-radius: 12px;
  flex: 50% 1;
}
.Schedulefilter .sector .item.yoga.active {
  color: #fff;
  background: #14E1DC;
}
.Schedulefilter .sector .item.yoga.active:after {
  opacity: 1;
}
.Schedulefilter .sector .item.fitness.active {
  color: #fff;
  background: #F00F64;
}
.Schedulefilter .sector .item.fitness.active:after {
  opacity: 1;
}
.Schedulefilter .sector .item.mood {
  background-size: 0.24rem !important;
  background-position: 94% !important;
  background-repeat: no-repeat !important;
  padding: 0 10px;
}
.Schedulefilter .sector .item.mood p {
  text-overflow: ellipsis;
  width: 85%;
  overflow: hidden;
  white-space: nowrap;
}
.Schedulefilter .filter_btn {
  width: 1.3333rem;
  height: 1rem;
  background: none;
  color: #ababab;
  font-weight: 700;
  margin-left: 7px;
  font-size: 0.35rem;
}
.Schedulefilter .locate {
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 8px 0px;
  margin: auto;
}
.Schedulefilter .locate .location {
  flex: 1 1;
}
.Schedulefilter .locate .location .active_location {
  flex: 1 1;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 0.2rem;
  background: #f2f2f2 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABlklEQVRYR+2W0VHDMBBE9yogHVACoQJybgA6ACqAToAKgAqABnyhAkIHKSFUcMxlZOM4tiXLcsiH9ZPJyCc972p9IhzZoCPjwQTkc2RSaFLIp4BvvjxDIjID8AZg4StKPL8CcMvM9vsXexF5AXCdeLPQ5V6Z+aYOpKHVYzzHzFu3qpb9G5Cq/mRZZkfmuC2bqeqaiE7GsKRtTVOHiObMvN5RyP6IyBzA1yGBADAzL4s991qHiNhpfz4QlMXd0l2Oxl4mIo8A7kaGKqPuBXL2vQO4HANKVT+zLGv8ALd2e/tyq+qSiM5SQqnqNxEtmHnTtG7n9cMOuYNKkjyXKIPZtoneQM46k1YSqbSTqCggB5UieXuJigZyUEOab2OiBgFZcZ7ndsgvetr3wcxXoTW97tQueSsiOg3ZwJeowQoV7SUkefUeFfIC9kwvhYpFRcQssNtl1zjvind07NsKReQewEPLfFCiklhWXaTl2vvEzAYbNaIsq0FVe15wvJNbVoOyr/km5szUwQYrFOVLR9EE5FN0Usin0C93fpwlgaX9awAAAABJRU5ErkJggg==) no-repeat 96% center;
  background-size: 0.24rem;
  text-align: left;
  color: #ababab;
  padding: 0 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@keyframes am-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.my-service-wrapper .components_title {
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  line-height: 28px;
  padding: 50px 0 20px;
  display: flex;
  align-items: center; }
  .my-service-wrapper .components_title .back {
    border: none;
    background: none;
    outline: none;
    float: left;
    line-height: 1;
    cursor: pointer; }
    .my-service-wrapper .components_title .back .iconfont {
      font-family: "iconfont" !important;
      font-size: 20px;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 700; }

.my-service-wrapper .mypurchases .components_title {
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  line-height: 28px;
  padding: 50px 0 20px; }
  .my-service-wrapper .mypurchases .components_title button .back {
    border: none;
    background: none;
    outline: none;
    float: left;
    line-height: 1;
    cursor: pointer; }

.my-service-wrapper .getServicePlans ul {
  width: 842px;
  margin: 30px auto 0; }
  .my-service-wrapper .getServicePlans ul li {
    border-top: 1px solid #BFBFBF;
    position: relative;
    padding: 10px 30px 10px 20px; }
    .my-service-wrapper .getServicePlans ul li:last-child {
      border-bottom: 1px solid #bfbfbf; }
    .my-service-wrapper .getServicePlans ul li h4 {
      font-size: 18px;
      color: #000;
      font-weight: bold;
      margin-bottom: 5px; }
    .my-service-wrapper .getServicePlans ul li p {
      color: #BFBFBF;
      font-size: 14px;
      line-height: 1.5;
      margin: 0; }
    .my-service-wrapper .getServicePlans ul li .aside {
      position: absolute;
      right: 20px;
      bottom: 10px;
      display: flex; }
    .my-service-wrapper .getServicePlans ul li h5 {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 0;
      line-height: 20px;
      color: #000;
      font-weight: bold; }

.my-service-wrapper .anticon-question-circle {
  display: block !important;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.2) !important; }

.my-service-wrapper .servicePop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  animation-name: am-fade;
  animation-duration: 0.2s;
  animation-timing-function: linear; }
  .my-service-wrapper .servicePop .pop_content {
    width: 485px;
    border-radius: 30px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    padding: 50px 30px 30px;
    animation-name: am-fade;
    animation-duration: 0.5s;
    animation-timing-function: linear; }

.my-service-wrapper h3 {
  font-weight: bold;
  font-size: 22px;
  color: #000;
  text-align: center;
  margin-bottom: 30px; }

.my-service-wrapper .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #aaa;
  font-size: 20px;
  line-height: 2;
  margin-bottom: 15px; }

#root .is-mobile .my-service-wrapper .components_title {
  text-align: center;
  font-size: 0.4rem !important;
  font-weight: normal !important;
  display: flex;
  border-bottom: 1px solid #ddd;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  justify-content: space-evenly;
  height: 1.28rem;
  line-height: 1.28rem;
  margin-bottom: 0;
  background: white;
  z-index: 1;
  padding: unset; }
  #root .is-mobile .my-service-wrapper .components_title .back {
    height: 1.28rem;
    width: 1rem;
    text-align: center;
    position: absolute;
    left: 0px;
    z-index: 3;
    line-height: 1.15; }
    #root .is-mobile .my-service-wrapper .components_title .back .iconfont {
      font-family: "iconfont" !important;
      font-size: 16px;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal; }
  #root .is-mobile .my-service-wrapper .components_title span {
    flex: 1 1 auto;
    justify-content: center;
    color: #000; }

#root .is-mobile .my-service-wrapper .getServicePlans ul {
  width: 100%;
  margin: auto; }
  #root .is-mobile .my-service-wrapper .getServicePlans ul li {
    padding: 0.2667rem;
    border-bottom: 1px solid #cecece;
    border-top: unset; }
    #root .is-mobile .my-service-wrapper .getServicePlans ul li h4 {
      font-size: 0.4rem;
      line-height: 1;
      margin-bottom: 0.1333rem; }
    #root .is-mobile .my-service-wrapper .getServicePlans ul li p {
      font-size: 0.34rem;
      line-height: 1.25;
      color: #555; }

#root .is-mobile .my-service-wrapper .servicePop .pop_content {
  width: 95%;
  padding: .6667rem .4667rem .5333rem;
  border-radius: .4rem; }

#root .is-mobile .my-service-wrapper h3 {
  font-size: .48rem;
  margin-bottom: 15px; }

#root .is-mobile .my-service-wrapper .item {
  font-size: .48rem;
  margin-bottom: unset;
  color: #555; }

#root .is-mobile .my-service-wrapper .list .signInItem .date {
  color: #000;
  background: #f2f2f2;
  padding: 0 0.4rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-weight: normal;
  font-size: .32rem; }

#root .is-mobile .my-service-wrapper .list .signInItem ul {
  margin-bottom: 0; }
  #root .is-mobile .my-service-wrapper .list .signInItem ul .class_detail_wrapper {
    position: relative; }
  #root .is-mobile .my-service-wrapper .list .signInItem ul .class_detail_overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.listfilter {
  height: 1.5333rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  font-size: 0.3733rem;
  box-shadow: 0 1px 0 0 #f2f2f2;
  padding: 0 0.2667rem; }
  .listfilter .am-list-item {
    padding-left: 0; }
    .listfilter .am-list-item .am-list-line {
      padding-right: 0.4rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABlklEQVRYR+2W0VHDMBBE9yogHVACoQJybgA6ACqAToAKgAqABnyhAkIHKSFUcMxlZOM4tiXLcsiH9ZPJyCc972p9IhzZoCPjwQTkc2RSaFLIp4BvvjxDIjID8AZg4StKPL8CcMvM9vsXexF5AXCdeLPQ5V6Z+aYOpKHVYzzHzFu3qpb9G5Cq/mRZZkfmuC2bqeqaiE7GsKRtTVOHiObMvN5RyP6IyBzA1yGBADAzL4s991qHiNhpfz4QlMXd0l2Oxl4mIo8A7kaGKqPuBXL2vQO4HANKVT+zLGv8ALd2e/tyq+qSiM5SQqnqNxEtmHnTtG7n9cMOuYNKkjyXKIPZtoneQM46k1YSqbSTqCggB5UieXuJigZyUEOab2OiBgFZcZ7ndsgvetr3wcxXoTW97tQueSsiOg3ZwJeowQoV7SUkefUeFfIC9kwvhYpFRcQssNtl1zjvind07NsKReQewEPLfFCiklhWXaTl2vvEzAYbNaIsq0FVe15wvJNbVoOyr/km5szUwQYrFOVLR9EE5FN0Usin0C93fpwlgaX9awAAAABJRU5ErkJggg==) no-repeat right center;
      background-size: 0.2667rem; }
      .listfilter .am-list-item .am-list-line .am-list-extra {
        flex-basis: 100%; }
  .listfilter .order {
    position: absolute;
    right: 0.2667rem; }
    .listfilter .order button {
      width: 0.8rem;
      height: 1.5333rem;
      background: none;
      color: #888; }

.myProfile .dummy-link {
  display: none !important; }

.favourite-content-wrapper .favourite-card {
  border-radius: 16px;
  overflow: hidden; }
  .favourite-content-wrapper .favourite-card img {
    border-radius: 16px 16px 0 0; }
  .favourite-content-wrapper .favourite-card .ant-card-body {
    padding: 16px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .favourite-content-wrapper .favourite-card .ant-card-body .location-body {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .favourite-content-wrapper .favourite-card .ant-card-body .teacher-body {
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .favourite-content-wrapper .favourite-card .card-star {
    position: absolute;
    top: 10px;
    text-align: start;
    margin-left: 10px;
    color: #ffaC33;
    width: auto;
    left: 0; }
  .favourite-content-wrapper .favourite-card .ant-card-cover div {
    overflow: hidden; }
  #root .is-mobile .favourite-content-wrapper .favourite-card .ant-card-body {
    padding: 8px;
    height: 65px;
    line-height: 16px; }
  #root .is-mobile .favourite-content-wrapper .favourite-card .ant-card-cover img {
    max-height: 112px;
    min-height: 112px; }
  #root .is-desktop .favourite-content-wrapper .favourite-card .ant-card-cover img {
    min-height: 200px;
    max-height: 200px; }

#root .is-mobile .favourite-content-wrapper .teacher-card .ant-card-body {
  height: 55px !important; }

.favourite-content-wrapper .favourite-class-list .ant-list-item {
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  height: 57px;
  align-content: center; }

.favourite-content-wrapper .favourite-class-list .ant-list-items {
  padding: 5px; }

.favourite-content-wrapper .favourite-class-list .ant-list-item-meta {
  align-items: center; }

.favourite-content-wrapper .favourite-class-list .ant-list-item-meta-content {
  align-self: center; }

.favourite-content-wrapper .favourite-class-list .ant-list-item-meta-avatar, .favourite-content-wrapper .favourite-class-list .ant-list-item-meta-content {
  cursor: pointer; }

.favourite-content-wrapper .favourite-class-list .ant-list-item-meta-title {
  margin-bottom: 0; }
  .favourite-content-wrapper .favourite-class-list .ant-list-item-meta-title .class-name {
    margin: auto;
    font-size: 0.3rem;
    line-height: 0.4rem; }
    #root .is-desktop .favourite-content-wrapper .favourite-class-list .ant-list-item-meta-title .class-name {
      font-size: 0.4rem;
      text-align: start;
      line-height: 0.5rem; }

.favourite-content-wrapper .favourite-class-list .list-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 5px;
  gap: 5px; }
  .favourite-content-wrapper .favourite-class-list .list-footer button {
    border: 0;
    border-radius: 8px;
    background-color: #f2f2f8; }

.alerts {
  height: 100%;
  background: #fff;
}
.alerts .alerts_head {
  height: 1.28rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 0 0 #cecece;
  position: relative;
  z-index: 2;
}
.alerts .alerts_head .back {
  width: auto;
  height: 1.28rem;
  width: 1rem;
  text-align: center;
  position: absolute;
  left: 0px;
  background: none;
  z-index: 3;
}
.alerts .alerts_head .item {
  text-align: center;
  height: 100%;
  font-size: 0.3733rem;
  padding: 0 10%;
}
.alerts .alerts_head .item span {
  line-height: 1.28rem;
  display: inline-block;
  position: relative;
  color: #bbb;
  transition: 0.5s;
  padding: 0 0.2667rem;
}
.alerts .alerts_head .item span:after {
  content: '';
  width: 100%;
  height: 0.1333rem;
  background: #404040;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  transition: 0.5s;
  opacity: 0;
}
.alerts .alerts_head .item.active span {
  color: #000;
}
.alerts .alerts_head .item.active span:after {
  opacity: 1;
}
.alerts .alerts_content {
  height: calc(100% - 1.28rem);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.alerts .user_notice {
  background: #ffebeb;
  padding: 0.4rem 0.4rem 0.5333rem;
}
.alerts .user_notice .title {
  font-size: 0.3733rem;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 0.2667rem;
  color: #000;
  font-weight: bold;
}
.alerts .user_notice .user_notice_content {
  font-size: 0.32rem;
  color: #222;
  line-height: 1.3;
}
.alerts .down {
  flex: 1 1;
  position: relative;
  padding-bottom: 1.8667rem;
}
.alerts .more_btn {
  width: 94%;
  margin: 0 auto;
  position: absolute;
  bottom: 0.2667rem;
  left: 3%;
  height: 1.3333rem;
  line-height: 1.3333rem;
  border-radius: 0.2rem;
  text-align: center;
  font-size: 0.3733rem;
  background: #F1F1F1;
  color: #888;
}
.alertList .am-list-line .am-list-content {
  font-size: 0.3733rem;
  color: #888;
  padding-right: 0.4rem;
  position: relative;
}
.alertList .am-list-line .am-list-content .iconfont {
  color: #EC1C23;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.48rem;
}
.alertList .am-list-line .am-list-arrow {
  margin-left: 0.1333rem;
  width: 12px;
  height: 12px;
}
.location_alerts {
  padding: 0.4rem;
}
.location_alerts .title {
  font-size: 0.3733rem;
  text-align: center;
  line-height: 1.3;
  color: #000;
  margin-bottom: 0.08rem;
  font-weight: bold;
}
.location_alerts .alerts_content {
  margin-top: 0.4rem;
  font-size: 0.32rem;
  line-height: 1.3;
  color: #888;
}
.location_alerts .alerts_content a {
  color: #0079FF;
}

.myCancellations {
  background: #fff;
  height: 100%; }
  .myCancellations .nav {
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    background: white;
    z-index: 6;
    top: 0px; }
  .myCancellations .back {
    width: auto;
    height: 1.28rem;
    width: 1rem;
    text-align: center;
    position: absolute;
    left: 0px;
    background: none;
    z-index: 3; }
  .myCancellations .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 1.28rem;
    line-height: 1.28rem;
    margin-bottom: 0;
    border-bottom: 1px solid #ddd; }
    .myCancellations .navbar-nav li a {
      line-height: 1.28rem;
      display: inline-block;
      position: relative;
      color: #bbb;
      transition: 0.5s;
      padding: 0 0.2667rem;
      font-size: 0.3467rem; }
      .myCancellations .navbar-nav li a:after {
        content: '';
        width: 100%;
        height: 0.1333rem;
        background: #404040;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        transition: 0.5s;
        opacity: 0; }
    .myCancellations .navbar-nav li .active {
      color: #000; }
      .myCancellations .navbar-nav li .active:after {
        opacity: 1; }
  .myCancellations .scroll_list {
    height: 100%;
    overflow-y: scroll; }
  .myCancellations .filter {
    height: 1.5333rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f2f2f2; }
    .myCancellations .filter .date {
      font-size: 0.4rem; }
      .myCancellations .filter .date .iconfont {
        font-size: 0.32rem;
        margin-left: 0.1333rem;
        color: #b9b9b9; }
    .myCancellations .filter .order {
      position: absolute;
      right: 0.2667rem; }
      .myCancellations .filter .order button {
        width: 0.8rem;
        height: 1.5333rem;
        background: none;
        color: #888; }
  .myCancellations .list .item .date {
    color: #000;
    background: #f2f2f2;
    padding: 0 0.4rem;
    height: 0.8rem;
    line-height: 0.8rem;
    font-weight: normal;
    font-size: .32rem; }
  .myCancellations .list .item .class_detail_wrapper {
    position: relative; }
    .myCancellations .list .item .class_detail_wrapper .class_detail_overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
  .myCancellations .list .item li .divOverlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }

.cancelDetails {
  height: 100%; }
  .cancelDetails .tab {
    height: 1.28rem;
    display: flex;
    justify-content: center;
    box-shadow: 0 1px 0 0 #cecece;
    position: relative;
    z-index: 2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white; }
    .cancelDetails .tab .back {
      font-size: 0.3733rem;
      height: 1.28rem;
      width: 1rem;
      text-align: center;
      position: absolute;
      left: 0;
      background: none;
      z-index: 3; }
    .cancelDetails .tab .item {
      width: auto;
      padding: 0 8%;
      text-align: center;
      height: 100%;
      font-size: 0.3467rem; }
      .cancelDetails .tab .item.black span {
        color: #000; }
      .cancelDetails .tab .item span {
        line-height: 1.28rem;
        display: inline-block;
        position: relative;
        color: #bbb;
        transition: 0.5s; }
        .cancelDetails .tab .item span:after {
          content: '';
          width: 100%;
          height: 0.1333rem;
          background: #404040;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          transition: 0.5s;
          opacity: 0; }
      .cancelDetails .tab .item.active span {
        color: #000; }
        .cancelDetails .tab .item.active span:after {
          opacity: 1; }
  .cancelDetails .cancelContent {
    overflow: scroll; }
  .cancelDetails .item {
    display: block; }
    .cancelDetails .item li {
      display: flex;
      justify-content: space-between;
      font-size: 0.3467rem;
      color: #808080;
      border-bottom: 1px solid #f2f2f2;
      height: 1.2667rem;
      line-height: 1.2667rem;
      padding: 0 0.2667rem; }
      .cancelDetails .item li:last-child {
        border-bottom: none; }
      .cancelDetails .item li .name {
        flex-basis: 40%; }
      .cancelDetails .item li .info {
        flex-basis: 60%;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .cancelDetails hr {
    height: 0.48rem;
    background: #f2f2f2;
    padding: 0;
    border: none; }

.region_lang_choose {
  position: relative;
  z-index: 99; }
  .region_lang_choose .title {
    font-size: 0.4267rem;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    text-align: center; }
  .region_lang_choose .pop_content {
    background: #fff;
    overflow: hidden;
    border-radius: 0.2667rem;
    margin: 0.5333rem 0;
    transition: 0.5s; }
    .region_lang_choose .pop_content .panel {
      height: 2.3333rem;
      border-bottom: 1px solid #dedede;
      padding: 0 0.4rem;
      position: relative;
      display: flex;
      align-items: center; }
      .region_lang_choose .pop_content .panel .name {
        font-weight: bold;
        font-size: 0.4rem;
        color: #000;
        margin-right: 0.2rem; }
      .region_lang_choose .pop_content .panel .active {
        color: #333;
        font-size: 0.4rem; }
      .region_lang_choose .pop_content .panel .iconfont {
        position: absolute;
        right: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.4267rem;
        color: #888; }
      .region_lang_choose .pop_content .panel:last-child {
        border-bottom: none; }
    .region_lang_choose .pop_content .select_title {
      height: 0.96rem;
      line-height: 0.96rem;
      text-align: center;
      position: relative;
      color: #737373;
      background: #f2f2f2;
      font-size: 0.3733rem; }
      .region_lang_choose .pop_content .select_title .back {
        width: 0.96rem;
        height: 0.96rem;
        background: none;
        position: absolute;
        left: 0;
        top: 0; }
        .region_lang_choose .pop_content .select_title .back .iconfont {
          font-size: 0.4533rem;
          color: #777; }
    .region_lang_choose .pop_content .list li {
      height: 1.2rem;
      line-height: 1.2rem;
      border-bottom: 1px solid #dadada;
      padding: 0 0.4rem;
      position: relative;
      font-size: 0.4rem;
      color: #333; }
      .region_lang_choose .pop_content .list li:last-child {
        border-bottom: none; }
      .region_lang_choose .pop_content .list li .iconfont {
        color: #777;
        position: absolute;
        right: 0.4rem;
        top: 50%;
        transform: translateY(-50%); }
  .region_lang_choose .opera {
    text-align: center; }
    .region_lang_choose .opera button {
      width: 2.6667rem;
      height: 0.96rem;
      line-height: 0.96rem;
      border-radius: 0.16rem;
      color: #888;
      font-size: 0.3733rem;
      background: #fff; }

.am-modal-button-group-h .am-modal-button:first-child {
  color: #108ee9;
  border-bottom: 1px solid #ddd; }

.am-modal-button-group-h {
  display: block; }

.pop_list_wra {
  position: relative;
  z-index: 99; }
  .pop_list_wra.silent {
    display: none; }
  .pop_list_wra .titile {
    font-size: 0.4267rem;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    text-align: center; }
  .pop_list_wra .pop_content {
    background: #fff;
    overflow: hidden;
    border-radius: 0.2667rem;
    margin: 0.5333rem 0;
    transition: 0.5s; }
    .pop_list_wra .pop_content .panel {
      height: 1.3333rem;
      border-bottom: 1px solid #dedede;
      padding: 0 0.4rem;
      position: relative;
      display: flex;
      align-items: center; }
      .pop_list_wra .pop_content .panel .name {
        font-size: 0.3733rem;
        color: #333;
        margin-right: 0.2rem; }
      .pop_list_wra .pop_content .panel .iconfont {
        position: absolute;
        right: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.48rem;
        color: #888; }
      .pop_list_wra .pop_content .panel:last-child {
        border-bottom: none; }
  .pop_list_wra .opera {
    text-align: center; }
    .pop_list_wra .opera button {
      width: 2.6667rem;
      height: 0.96rem;
      line-height: 0.96rem;
      border-radius: 0.16rem;
      color: #888;
      font-size: 0.3733rem;
      background: #fff; }

.teachersInfo {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 0.4rem;
  padding: 0.4rem;
  position: relative;
  z-index: 10; }
  .teachersInfo .content {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0 0.4rem; }
  .teachersInfo .avatar {
    width: 4rem;
    height: 4rem;
    margin: 0 auto; }
    .teachersInfo .avatar img {
      width: 100%;
      height: 100%;
      border: 1px solid #cecece;
      border-radius: 0.2667rem; }
  .teachersInfo .name {
    text-align: center;
    color: #000;
    margin: 0.4rem 0;
    display: block; }
  .teachersInfo .disc {
    font-size: 0.3733rem;
    color: #888;
    width: 100%;
    display: block;
    white-space: pre-line; }
  .teachersInfo .locations {
    margin-top: 0.4rem; }
    .teachersInfo .locations .title {
      border-bottom: 1px solid #000;
      padding-bottom: 0.1067rem;
      font-weight: bold;
      text-align: start !important; }
    .teachersInfo .locations ul {
      padding-top: 0.12rem; }
      .teachersInfo .locations ul li {
        list-style: inside;
        line-height: 1.5em; }
    .teachersInfo .locations .title {
      font-size: 0.4rem !important; }
    .teachersInfo .locations li {
      font-size: 0.3467rem; }
  .teachersInfo .schedule {
    margin-top: 0.4rem;
    position: relative; }
    .teachersInfo .schedule .title {
      border-bottom: 1px solid #000;
      font-size: 0.4rem !important;
      padding-bottom: 0.1067rem;
      font-weight: bold;
      text-align: start !important; }
    .teachersInfo .schedule .filter {
      display: flex;
      position: absolute;
      top: 0.1rem;
      right: 0;
      width: 60%;
      text-align: center;
      justify-content: flex-end; }
      .teachersInfo .schedule .filter .filterTitle {
        color: black;
        font-weight: bold;
        font-size: .33rem; }
    .teachersInfo .schedule li {
      border-bottom: 1px solid #cecece;
      padding: 0.2rem 0; }
      .teachersInfo .schedule li .date {
        font-size: .32rem;
        color: black; }
      .teachersInfo .schedule li .class_info h4 {
        font-size: .4rem;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: .1rem 0; }
      .teachersInfo .schedule li .class_info .time-name {
        display: flex;
        flex-direction: row;
        grid-gap: 5px;
        gap: 5px; }
        .teachersInfo .schedule li .class_info .time-name .class-name {
          flex: 1 1;
          -webkit-line-clamp: 2;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          display: -webkit-box !important;
          white-space: normal !important; }
      .teachersInfo .schedule li .class_info .down {
        width: 100%;
        height: 1.1rem;
        display: flex;
        justify-content: space-between; }
        .teachersInfo .schedule li .class_info .down .opera {
          right: 0;
          position: absolute;
          margin-top: 0;
          justify-content: center; }
        .teachersInfo .schedule li .class_info .down .left {
          width: calc(100% - 2.3rem);
          display: flex;
          flex-direction: column;
          justify-content: space-around; }
          .teachersInfo .schedule li .class_info .down .left .leftInfo {
            display: flex; }
            .teachersInfo .schedule li .class_info .down .left .leftInfo .infoContent {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap; }
            .teachersInfo .schedule li .class_info .down .left .leftInfo .teacherName {
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content; }
            .teachersInfo .schedule li .class_info .down .left .leftInfo .timeContent {
              white-space: nowrap; }
            .teachersInfo .schedule li .class_info .down .left .leftInfo .mood {
              display: flex; }
              .teachersInfo .schedule li .class_info .down .left .leftInfo .mood .moodName {
                font-weight: bold; }
      .teachersInfo .schedule li .iconfont {
        font-size: .3rem; }
  .teachersInfo .buttonSection {
    display: flex; }
    .teachersInfo .buttonSection button {
      flex: 1 1;
      margin: 0.3em;
      border-radius: 10px;
      background: #fff;
      border: solid #d8d8d8 1px;
      color: #000;
      padding: 8px; }
  .teachersInfo .opera {
    text-align: center;
    margin-top: 0.4rem; }
    .teachersInfo .opera .close {
      background: #f2f2f2;
      color: #888;
      border-radius: 10px;
      padding: 8px 15px;
      min-width: 2rem;
      border: 0; }

.classesInfo {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 0.4rem;
  padding: 0.4rem;
  position: relative;
  z-index: 10; }
  .classesInfo .content {
    height: 70vh;
    overflow-y: scroll;
    padding: 0 0.4rem; }
    .classesInfo .content .class {
      font-weight: bold; }
  .classesInfo .name {
    text-align: center;
    font-size: 0.4267rem;
    color: #000;
    margin: 0.2rem 0;
    display: block; }
  .classesInfo .mood {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .classesInfo .mood .item {
      flex: 1 1; }
    .classesInfo .mood .levelId {
      text-align: start; }
    .classesInfo .mood .moodName {
      text-align: end; }
    .classesInfo .mood .name {
      margin: 0; }
    .classesInfo .mood .align {
      text-align: center; }
  .classesInfo .pillar {
    display: flex;
    justify-content: center;
    margin: 0.4rem 0;
    font-size: 0.4rem;
    height: 0.6rem;
    align-items: center; }
    .classesInfo .pillar em {
      width: 0.6rem;
      height: 0.6rem;
      margin-left: 0.2667rem; }
      .classesInfo .pillar em img {
        width: 100%;
        height: 100%; }
  .classesInfo .disc {
    font-size: 0.3733rem;
    color: #888;
    width: 100%;
    display: block;
    white-space: pre-line; }
  .classesInfo .locations {
    margin-top: 0.4rem; }
    .classesInfo .locations .title {
      border-bottom: 1px solid #000;
      font-size: 0.4rem !important;
      padding-bottom: 0.1067rem;
      font-weight: bold;
      text-align: start !important; }
    .classesInfo .locations ul {
      padding-top: 0.12rem; }
      .classesInfo .locations ul li {
        list-style: inside;
        line-height: 1.5em;
        font-size: 0.3467rem; }
  .classesInfo .schedule {
    margin-top: 0.4rem;
    position: relative; }
    .classesInfo .schedule .title {
      border-bottom: 1px solid #000;
      font-size: 0.4rem !important;
      padding-bottom: 0.1067rem;
      font-weight: bold;
      text-align: start !important; }
    .classesInfo .schedule .filter {
      display: flex;
      position: absolute;
      top: 0.1rem;
      right: 0;
      width: 60%;
      text-align: center;
      justify-content: flex-end; }
      .classesInfo .schedule .filter .filterTitle {
        color: black;
        font-weight: bold;
        font-size: .33rem; }
    .classesInfo .schedule li {
      border-bottom: 1px solid #cecece;
      padding: 0.2rem 0; }
      .classesInfo .schedule li .date {
        font-size: .32rem;
        color: black; }
      .classesInfo .schedule li .class_info h4 {
        font-size: .4rem;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: .1rem 0; }
      .classesInfo .schedule li .class_info .time-name {
        display: flex;
        flex-direction: row;
        grid-gap: 5px;
        gap: 5px; }
        .classesInfo .schedule li .class_info .time-name .class-name {
          flex: 1 1;
          -webkit-line-clamp: 2;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          display: -webkit-box !important;
          white-space: normal !important; }
      .classesInfo .schedule li .class_info .down {
        width: 100%;
        height: 1.1rem;
        display: flex;
        justify-content: space-between; }
        .classesInfo .schedule li .class_info .down .opera {
          right: 0;
          position: absolute;
          margin-top: 0;
          justify-content: center; }
        .classesInfo .schedule li .class_info .down .left {
          width: calc(100% - 2.3rem);
          display: flex;
          flex-direction: column;
          justify-content: space-around; }
          .classesInfo .schedule li .class_info .down .left .leftInfo {
            display: flex; }
            .classesInfo .schedule li .class_info .down .left .leftInfo .infoContent {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap; }
            .classesInfo .schedule li .class_info .down .left .leftInfo .teacherName {
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content; }
            .classesInfo .schedule li .class_info .down .left .leftInfo .timeContent {
              white-space: nowrap; }
            .classesInfo .schedule li .class_info .down .left .leftInfo .mood {
              display: flex; }
              .classesInfo .schedule li .class_info .down .left .leftInfo .mood .moodName {
                font-weight: bold; }
      .classesInfo .schedule li .iconfont {
        font-size: .3rem; }
  .classesInfo .buttonSection {
    display: flex; }
    .classesInfo .buttonSection button {
      flex: 1 1;
      margin: 0.3em;
      border-radius: 10px;
      background: #fff;
      border: solid #d8d8d8 1px;
      color: #000;
      padding: 8px; }
  .classesInfo .opera {
    text-align: center;
    margin-top: 0.4rem; }
    .classesInfo .opera .close {
      background: #f2f2f2;
      color: #888;
      border-radius: 10px;
      padding: 8px 15px;
      min-width: 2rem;
      border: 0; }

.pop_list_wra {
  position: relative;
  z-index: 99;
}
.pop_list_wra .titile {
  font-size: 0.4267rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  text-align: center;
}
.pop_list_wra .pop_content {
  background: #fff;
  overflow: hidden;
  border-radius: 0.2667rem;
  margin: 0.5333rem 0;
  transition: 0.5s;
}
.pop_list_wra .pop_content ul {
  max-height: 8rem;
  overflow-y: scroll;
}
.pop_list_wra .pop_content .panel {
  height: 1.3333rem;
  border-bottom: 1px solid #dedede;
  padding: 0 0.4rem;
  position: relative;
  display: flex;
  align-items: center;
}
.pop_list_wra .pop_content .panel .name {
  font-size: 0.3733rem;
  color: #333;
  margin-right: 0.2rem;
}
.pop_list_wra .pop_content .panel .iconfont {
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.48rem;
  color: #888;
}
.pop_list_wra .pop_content .panel:last-child {
  border-bottom: none;
}
.pop_list_wra .pop_content .panel .favourite-icon {
  right: 0.9rem;
  position: absolute;
  font-size: 0.4rem;
}
.pop_list_wra .pop_content .panel .am-icon {
  display: none;
  margin-left: auto;
  color: #2378BE;
  right: 0.3rem;
  position: absolute;
}
.pop_list_wra .pop_content .panel.choice .am-icon {
  display: block;
}
.pop_list_wra .pop_content .panel.disabled {
  pointer-events: none;
}
.pop_list_wra .pop_content .panel.disabled .name {
  color: #9195A3 !important;
}
.pop_list_wra .opera {
  text-align: center;
}
.pop_list_wra .opera button {
  width: 2.6667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 0.16rem;
  color: #333;
  font-size: 0.3733rem;
  background: #fff;
}
.pop_list_wra .opera button:disabled,
.pop_list_wra .opera button[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.schedule-filter-pop-wrapper {
  position: relative;
  z-index: 99;
}
.schedule-filter-pop-wrapper .title {
  font-size: 0.4267rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  text-align: center;
}
.schedule-filter-pop-wrapper .pop_content {
  background: #fff;
  overflow: hidden;
  border-radius: 0.2667rem;
  margin: 0.5333rem auto;
  transition: 0.5s;
  width: 90%;
}
.schedule-filter-pop-wrapper .pop_content .panel {
  height: 1.5rem;
  border-bottom: 1px solid #dedede;
  padding: 0 0.4rem;
  position: relative;
  display: flex;
  align-items: center;
}
.schedule-filter-pop-wrapper .pop_content .panel .name {
  font-weight: bold;
  font-size: 0.4rem;
  color: #000;
  margin-right: 0.2rem;
}
.schedule-filter-pop-wrapper .pop_content .panel .active {
  color: #333;
  font-size: 0.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  max-width: calc(100% - 2.6667rem);
  display: block;
}
.schedule-filter-pop-wrapper .pop_content .panel .iconfont {
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.4267rem;
  color: #888;
}
.schedule-filter-pop-wrapper .pop_content .panel:last-child {
  border-bottom: none;
}
.schedule-filter-pop-wrapper .pop_content .opera {
  margin-top: 0.667rem;
  padding-bottom: 0.6667rem;
  display: flex;
  justify-content: space-around;
}
.schedule-filter-pop-wrapper .pop_content .opera button {
  width: 2.8rem;
  height: 1.08rem;
  border-radius: 0.2667rem;
  font-size: 0.3733rem;
}
.schedule-filter-pop-wrapper .pop_content .opera button.reset {
  background: #f2f2f2;
  color: #808080;
}
.schedule-filter-pop-wrapper .pop_content .opera button.apply {
  background: #000;
  color: #fff;
}
.schedule-filter-pop-wrapper .pop_content .select_title {
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  position: relative;
  color: #737373;
  background: #f2f2f2;
  font-size: 0.3733rem;
}
.schedule-filter-pop-wrapper .pop_content .select_title .back {
  width: 0.96rem;
  height: 0.96rem;
  background: none;
  position: absolute;
  left: 0;
  top: 0;
}
.schedule-filter-pop-wrapper .pop_content .select_title .back .iconfont {
  font-size: 0.4533rem;
  color: #777;
}
.schedule-filter-pop-wrapper .pop_content .list {
  max-height: 7.2rem;
  overflow-y: scroll;
}
.schedule-filter-pop-wrapper .pop_content .list li {
  height: 1.2rem;
  line-height: 1.2rem;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0.4rem;
  position: relative;
  font-size: 0.4rem;
  color: #333;
  padding-right: 1.2rem;
}
.schedule-filter-pop-wrapper .pop_content .list li span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.3733rem;
}
.schedule-filter-pop-wrapper .pop_content .list li:last-child {
  border-bottom: none;
}
.schedule-filter-pop-wrapper .pop_content .list li .iconfont {
  color: #777;
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
}
.schedule-filter-pop-wrapper .opera {
  text-align: center;
}
.schedule-filter-pop-wrapper .opera button {
  width: 2.6667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 0.16rem;
  color: #888;
  font-size: 0.3733rem;
  background: #fff;
}

.pop_list_wra {
  position: relative;
  z-index: 99;
}
.pop_list_wra .title {
  font-size: 0.4267rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  text-align: center;
}
.pop_list_wra .pop_content {
  background: #fff;
  overflow: hidden;
  border-radius: 0.2667rem;
  margin: 0.5333rem 0;
  transition: 0.5s;
}
.pop_list_wra .pop_content .moodUl {
  overflow-y: scroll;
}
.pop_list_wra .pop_content .panel {
  height: 1.3333rem;
  border-bottom: 1px solid #dedede;
  padding: 0 0.4rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop_list_wra .pop_content .panel .name {
  font-size: 0.4rem;
  color: #333;
  margin-right: 0.2rem;
  font-weight: bold;
}
.pop_list_wra .pop_content .panel .iconfont {
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.48rem;
  color: #888;
}
.pop_list_wra .pop_content .panel:last-child {
  border-bottom: none;
}
.pop_list_wra .pop_content .panel .am-icon {
  display: none;
  margin-left: auto;
  color: #2378BE;
  right: 0.3rem;
  position: absolute;
}
.pop_list_wra .pop_content .panel .icon-check:before {
  content: "\E663";
}
.pop_list_wra .pop_content .panel.choice .am-icon {
  display: block;
}
.pop_list_wra .pop_content .panel.disabled {
  pointer-events: none;
}
.pop_list_wra .pop_content .panel.disabled .name {
  color: #9195A3 !important;
}
.pop_list_wra .opera {
  text-align: center;
  display: flex;
  flex-direction: row;
}
.pop_list_wra .opera button {
  width: 2.6667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 0.16rem;
  color: #333;
  font-size: 0.3733rem;
  background: #fff;
  flex: auto;
  margin: 0 0.25rem;
}

.location-info {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 0.4rem;
  padding: 0 0 0.4rem;
  position: relative;
  z-index: 10;
  overflow: hidden; }
  .location-info .slick-dots li button, .location-info .slick-dots li.slick-active button {
    width: 6px;
    height: 6px;
    border-radius: 100%; }
  .location-info .content-wrapper {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0; }
    .location-info .content-wrapper .content {
      max-height: 70vh;
      padding: 0 0.8rem; }
  .location-info .avatar {
    width: 4rem;
    height: 4rem;
    margin: 0 auto; }
    .location-info .avatar img {
      width: 100%;
      height: 100%;
      border: 1px solid #cecece;
      border-radius: 0.2667rem; }
  .location-info .name {
    text-align: center;
    color: #000;
    margin: 0.4rem 0 0 0;
    display: block;
    font-weight: bold; }
  .location-info .district {
    text-align: center;
    color: #787878;
    display: block; }
  .location-info .disc {
    color: #888;
    width: 100%;
    display: block;
    white-space: pre-line; }
  .location-info .locations {
    margin-top: 0.4rem;
    color: #888; }
    .location-info .locations .title {
      border-bottom: 1px solid #cecece;
      padding-bottom: 0.1067rem;
      font-weight: bold; }
    .location-info .locations ul {
      padding-top: 0.12rem; }
      .location-info .locations ul li {
        list-style: inside;
        line-height: 1.5em;
        font-size: 0.3467rem; }
  .location-info .title-wrapper {
    border-bottom: 1px solid #000;
    padding-bottom: 0.1067rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    .location-info .title-wrapper .title {
      font-size: 0.4rem !important;
      text-align: start !important;
      font-weight: bold;
      flex-grow: 1; }
  .location-info .virtual-tour, .location-info .phone {
    margin-top: 0.2rem; }
    .location-info .virtual-tour button, .location-info .phone button {
      border-radius: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 155px;
      justify-content: center;
      margin: auto;
      background-color: #f2f2f8;
      border: none; }
      .location-info .virtual-tour button a, .location-info .phone button a {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 5px;
        gap: 5px; }
        .location-info .virtual-tour button a img, .location-info .phone button a img {
          width: 16px;
          height: 16px; }
  .location-info .opening-hour, .location-info .address, .location-info .about {
    margin-top: 0.4rem; }
    .location-info .opening-hour .htmlContentWrapper, .location-info .address .htmlContentWrapper, .location-info .about .htmlContentWrapper {
      padding: 0.2rem 0; }
  .location-info .about .about-col {
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    align-items: center; }
  .location-info .locations {
    margin-top: 0.4rem; }
    .location-info .locations .title {
      border-bottom: 1px solid #000;
      padding-bottom: 0.1067rem;
      font-weight: bold;
      text-align: start !important; }
    .location-info .locations ul {
      padding-top: 0.12rem; }
      .location-info .locations ul li {
        list-style: inside;
        line-height: 1.5em; }
  .location-info .schedule {
    margin-top: 0.4rem;
    position: relative; }
    .location-info .schedule .title {
      border-bottom: 1px solid #000;
      font-size: 0.4rem !important;
      padding-bottom: 0.1067rem;
      font-weight: bold;
      text-align: start !important; }
    .location-info .schedule .filter {
      display: flex;
      position: absolute;
      top: 0.1rem;
      right: 0;
      width: 60%;
      text-align: center;
      justify-content: flex-end; }
      .location-info .schedule .filter .filterTitle {
        color: black;
        font-weight: bold;
        font-size: .33rem; }
    .location-info .schedule li {
      border-bottom: 1px solid #cecece;
      padding: 0.2rem 0; }
      .location-info .schedule li .date {
        color: black; }
      .location-info .schedule li .class_info h4 {
        font-size: .4rem;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: .1rem 0; }
      .location-info .schedule li .class_info .time-name {
        display: flex;
        flex-direction: row;
        grid-gap: 5px;
        gap: 5px; }
        .location-info .schedule li .class_info .time-name .class-name {
          flex: 1 1;
          -webkit-line-clamp: 2;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          display: -webkit-box !important;
          white-space: normal !important; }
      .location-info .schedule li .class_info .down {
        width: 100%;
        height: 1.1rem;
        display: flex;
        justify-content: space-between; }
        .location-info .schedule li .class_info .down .opera {
          right: 0;
          position: absolute;
          margin-top: 0;
          justify-content: center; }
        .location-info .schedule li .class_info .down .left {
          width: calc(100% - 2.3rem);
          display: flex;
          flex-direction: column;
          justify-content: space-around; }
          .location-info .schedule li .class_info .down .left .leftInfo {
            display: flex; }
            .location-info .schedule li .class_info .down .left .leftInfo .infoContent {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap; }
            .location-info .schedule li .class_info .down .left .leftInfo .teacherName {
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content; }
            .location-info .schedule li .class_info .down .left .leftInfo .timeContent {
              white-space: nowrap; }
            .location-info .schedule li .class_info .down .left .leftInfo .mood {
              display: flex; }
              .location-info .schedule li .class_info .down .left .leftInfo .mood .moodName {
                font-weight: bold; }
      .location-info .schedule li .iconfont {
        font-size: .3rem; }
  .location-info .buttonSection {
    display: flex; }
    .location-info .buttonSection button {
      flex: 1 1;
      margin: 0.3em;
      border-radius: 0.2rem;
      background: #fff;
      border: solid #d8d8d8 1px;
      color: #000;
      padding: 8px; }
  .location-info .opera {
    text-align: center;
    margin-top: 0.4rem; }
    .location-info .opera .close {
      background: #f2f2f2;
      color: #888;
      border-radius: 10px;
      padding: 8px 15px;
      min-width: 2rem;
      border: 0; }

.modal-wrapper {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  display: none; }
  .modal-wrapper.show {
    transform-origin: center center;
    animation: open .5s;
    animation-fill-mode: forwards;
    display: block; }
  .modal-wrapper.close {
    display: block;
    transform-origin: center center;
    animation: hide .5s;
    animation-fill-mode: forwards; }
  .modal-wrapper .modal_content {
    width: 100%;
    height: 100%;
    padding: 0 0.2667rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; }
    #root .is-desktop .modal-wrapper .modal_content {
      width: 50%;
      margin: auto; }
      #root .is-desktop .modal-wrapper .modal_content p {
        margin-bottom: 0; }
    .modal-wrapper .modal_content .title,
    .modal-wrapper .modal_content .times {
      text-align: center;
      font-size: 0.48rem;
      font-weight: bold; }
    .modal-wrapper .modal_content .closeContent {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10; }

@keyframes open {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes hide {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/*===================================
=            limit lines            =
===================================*/
.line-1 {
  -webkit-line-clamp: 1 !important;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
  white-space: nowrap !important; }
  .line-1.no-dot {
    text-overflow: clip !important; }

.line-2 {
  -webkit-line-clamp: 2 !important;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  white-space: normal !important; }
  .line-2.no-dot {
    text-overflow: clip !important; }

.line-3 {
  -webkit-line-clamp: 3 !important;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  white-space: normal !important; }
  .line-3.no-dot {
    text-overflow: clip !important; }

.line-4 {
  -webkit-line-clamp: 4 !important;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  white-space: normal !important; }
  .line-4.no-dot {
    text-overflow: clip !important; }

.line-5 {
  -webkit-line-clamp: 5 !important;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  white-space: normal !important; }
  .line-5.no-dot {
    text-overflow: clip !important; }

.line-inf {
  overflow: visible !important;
  overflow: initial !important;
  white-space: normal !important; }

/*=====  End of limit lines  ======*/
/*==============================
=            colors            =
==============================*/
.color-yoga {
  color: "#14E1DC" !important; }

.yoga-bg {
  background-color: "#14E1DC" !important; }

.color-fitness {
  color: "#F00F64" !important; }

.fitness-bg {
  background-color: "#F00F64" !important; }

/*=====  End of colors  ======*/
/*==================================
=            background            =
==================================*/
.background-image-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.background-image-contain {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.pb-square {
  padding-bottom: 100% !important; }

.pb-16-9 {
  padding-bottom: 56.25% !important; }

.pb-9-16 {
  padding-bottom: 177.78% !important; }

.pb-3-4 {
  padding-bottom: 133.33% !important; }

.pb-6-5 {
  padding-bottom: 120% !important; }

.pb-16-5 {
  padding-bottom: 31.25% !important; }

/*=====  End of background  ======*/

