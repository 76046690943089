@import "../../../scss/mixins.scss";

.favourite-content-wrapper {

    .favourite-card {
        border-radius: 16px;
        overflow: hidden;

        img {
            border-radius: 16px 16px 0 0;
        }

        .ant-card-body {
            padding: 16px 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .location-body {
                height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .teacher-body {
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .card-star{
            position: absolute;
            top: 10px;
            text-align: start;
            margin-left: 10px;
            color: #ffaC33;
            width: auto;
            left: 0;
        }
        .ant-card-cover div{
            overflow: hidden;
        }

        @include mobileStyle {
            // height: 178px;
            .ant-card-body {
                padding: 8px;
                height: 65px;
                line-height: 16px;
            }
            .ant-card-cover img {
                max-height: 112px;
                min-height: 112px;
            }
        }
        @include desktopStyle {
            .ant-card-cover img {
                min-height: 200px;
                max-height: 200px;
            }
        }
    }

    .teacher-card {
        @include mobileStyle {
            .ant-card-body {
                height: 55px !important;
            }
        }
    }
    .favourite-class-list {

        .ant-list-item{
            padding: 12px;
            border-radius: 8px;
            margin-bottom: 10px;
            height: 57px;
            align-content: center;
        }
        .ant-list-items{
            padding: 5px;
        }
        .ant-list-item-meta{
            align-items: center;
        }
        .ant-list-item-meta-content{
            align-self: center;
        }
        .ant-list-item-meta-avatar, .ant-list-item-meta-content{
            cursor: pointer;
        }
        .ant-list-item-meta-title {
            margin-bottom: 0;
            .class-name{
                margin: auto;
                font-size: 0.3rem;
                line-height: 0.4rem;
                @include desktopStyle {
                    font-size: 0.4rem;
                    text-align: start;
                    line-height: 0.5rem;
                }
            }
        }
        .list-footer{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            button{
                border: 0;
                border-radius: 8px;
                background-color: #f2f2f8;
            }
        }
    }
}