.alerts {
  height: 100%;
  background: #fff;
}
.alerts .alerts_head {
  height: 1.28rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 0 0 #cecece;
  position: relative;
  z-index: 2;
}
.alerts .alerts_head .back {
  width: auto;
  height: 1.28rem;
  width: 1rem;
  text-align: center;
  position: absolute;
  left: 0px;
  background: none;
  z-index: 3;
}
.alerts .alerts_head .item {
  text-align: center;
  height: 100%;
  font-size: 0.3733rem;
  padding: 0 10%;
}
.alerts .alerts_head .item span {
  line-height: 1.28rem;
  display: inline-block;
  position: relative;
  color: #bbb;
  transition: 0.5s;
  padding: 0 0.2667rem;
}
.alerts .alerts_head .item span:after {
  content: '';
  width: 100%;
  height: 0.1333rem;
  background: #404040;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  transition: 0.5s;
  opacity: 0;
}
.alerts .alerts_head .item.active span {
  color: #000;
}
.alerts .alerts_head .item.active span:after {
  opacity: 1;
}
.alerts .alerts_content {
  height: calc(100% - 1.28rem);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.alerts .user_notice {
  background: #ffebeb;
  padding: 0.4rem 0.4rem 0.5333rem;
}
.alerts .user_notice .title {
  font-size: 0.3733rem;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 0.2667rem;
  color: #000;
  font-weight: bold;
}
.alerts .user_notice .user_notice_content {
  font-size: 0.32rem;
  color: #222;
  line-height: 1.3;
}
.alerts .down {
  flex: 1;
  position: relative;
  padding-bottom: 1.8667rem;
}
.alerts .more_btn {
  width: 94%;
  margin: 0 auto;
  position: absolute;
  bottom: 0.2667rem;
  left: 3%;
  height: 1.3333rem;
  line-height: 1.3333rem;
  border-radius: 0.2rem;
  text-align: center;
  font-size: 0.3733rem;
  background: #F1F1F1;
  color: #888;
}
.alertList .am-list-line .am-list-content {
  font-size: 0.3733rem;
  color: #888;
  padding-right: 0.4rem;
  position: relative;
}
.alertList .am-list-line .am-list-content .iconfont {
  color: #EC1C23;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.48rem;
}
.alertList .am-list-line .am-list-arrow {
  margin-left: 0.1333rem;
  width: 12px;
  height: 12px;
}
.location_alerts {
  padding: 0.4rem;
}
.location_alerts .title {
  font-size: 0.3733rem;
  text-align: center;
  line-height: 1.3;
  color: #000;
  margin-bottom: 0.08rem;
  font-weight: bold;
}
.location_alerts .alerts_content {
  margin-top: 0.4rem;
  font-size: 0.32rem;
  line-height: 1.3;
  color: #888;
}
.location_alerts .alerts_content a {
  color: #0079FF;
}
