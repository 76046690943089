.loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translateZ(1px);
  z-index: 2000;
}
.loading-wrapper.hide {
  display: none;
}
.loading-wrapper.loading-mobile .ant-spin-text {
  color: rgba(0, 0, 0, 0.65) !important;
}
.loading-wrapper .loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
