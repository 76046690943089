@import './variables';

@mixin platformStyle($class) {
  @at-root #root #{$class} & {
    @content;
  }
}

@mixin mobileStyle {
  @include platformStyle(#{map-get($platformClasses, mobile)}) {
    @content;
  }
}

@mixin desktopStyle {
  @include platformStyle(#{map-get($platformClasses, desktop)}) {
    @content;
  }
}

// text display max n line, then hidden
@mixin n-line($line) {
  -webkit-line-clamp: $line !important;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  @if $line>1 {
    display: -webkit-box !important;
    white-space: normal !important;
  }
  @else {
    display: block;
    white-space: nowrap !important;
  }
  &.no-dot {
    text-overflow: clip !important;
  }
}