.region_lang_choose{
    position: relative;
    z-index: 99;
    .title{
        font-size: 0.4267rem;
        font-weight: bold;
        color: #fff;
        line-height: 1;
        text-align: center;
    }
    .pop_content{
        background: #fff;
        overflow: hidden;
        border-radius: 0.2667rem;
        margin: 0.5333rem 0;
        transition: 0.5s;
        .panel{
            height: 2.3333rem;
            border-bottom: 1px solid #dedede;
            padding: 0 0.4rem;
            position: relative;
            display: flex;
            align-items: center;
            .name{
                font-weight: bold;
                font-size: 0.4rem;
                color: #000;
                margin-right: 0.2rem;
            }
            .active{
                color: #333;
                font-size: 0.4rem;
            }
            .iconfont{
                position: absolute;
                right: 0.4rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0.4267rem;
                color: #888;
            }
            &:last-child{
                border-bottom: none;
            }
        }
        .select_title{
            height: 0.96rem;
            line-height: 0.96rem;
            text-align: center;
            position: relative;
            color: #737373;
            background: #f2f2f2;
            font-size: 0.3733rem;
            .back{
                width: 0.96rem;
                height: 0.96rem;
                background: none;
                position: absolute;
                left: 0;
                top: 0;
                .iconfont{
                    font-size: 0.4533rem;
                    color: #777;
                }
            }
        }
        .list{ 
            li{
                height: 1.2rem;
                line-height: 1.2rem;
                border-bottom: 1px solid #dadada;
                padding: 0 0.4rem;
                position: relative;
                font-size: 0.4rem;
                color: #333;
                &:last-child{border-bottom: none;}
                .iconfont{
                    color: #777;
                    position: absolute;
                    right: 0.4rem;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .opera{
        text-align: center;
        button{
            width: 2.6667rem;
            height: 0.96rem;
            line-height: 0.96rem;
            border-radius: 0.16rem;
            color: #888;
            font-size: 0.3733rem;
            background: #fff;
        }
    }
}
.am-modal-button-group-h .am-modal-button:first-child{
    color: #108ee9;
    border-bottom: 1px solid #ddd;    
}
.am-modal-button-group-h{
    display: block;
}