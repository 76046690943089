.pop_list_wra {
  position: relative;
  z-index: 99;
}
.pop_list_wra .titile {
  font-size: 0.4267rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  text-align: center;
}
.pop_list_wra .pop_content {
  background: #fff;
  overflow: hidden;
  border-radius: 0.2667rem;
  margin: 0.5333rem 0;
  transition: 0.5s;
}
.pop_list_wra .pop_content ul {
  max-height: 8rem;
  overflow-y: scroll;
}
.pop_list_wra .pop_content .panel {
  height: 1.3333rem;
  border-bottom: 1px solid #dedede;
  padding: 0 0.4rem;
  position: relative;
  display: flex;
  align-items: center;
}
.pop_list_wra .pop_content .panel .name {
  font-size: 0.3733rem;
  color: #333;
  margin-right: 0.2rem;
}
.pop_list_wra .pop_content .panel .iconfont {
  position: absolute;
  right: 0.4rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.48rem;
  color: #888;
}
.pop_list_wra .pop_content .panel:last-child {
  border-bottom: none;
}
.pop_list_wra .pop_content .panel .favourite-icon {
  right: 0.9rem;
  position: absolute;
  font-size: 0.4rem;
}
.pop_list_wra .pop_content .panel .am-icon {
  display: none;
  margin-left: auto;
  color: #2378BE;
  right: 0.3rem;
  position: absolute;
}
.pop_list_wra .pop_content .panel.choice .am-icon {
  display: block;
}
.pop_list_wra .pop_content .panel.disabled {
  pointer-events: none;
}
.pop_list_wra .pop_content .panel.disabled .name {
  color: #9195A3 !important;
}
.pop_list_wra .opera {
  text-align: center;
}
.pop_list_wra .opera button {
  width: 2.6667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 0.16rem;
  color: #333;
  font-size: 0.3733rem;
  background: #fff;
}
.pop_list_wra .opera button:disabled,
.pop_list_wra .opera button[disabled] {
  color: rgba(0, 0, 0, 0.25);
}
