@import "../../scss/mixins";

.my-service-wrapper {
    @keyframes am-fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .components_title {
        font-size: 20px;
        font-weight: bold;
        overflow: hidden;
        line-height: 28px;
        padding: 50px 0 20px;
        display: flex;
        align-items: center;
        .back {
            border: none;
            background: none;
            outline: none;
            float: left;
            line-height: 1;
            cursor: pointer;
            // line-height: 2rem;
            .iconfont {
                font-family: "iconfont" !important;
                font-size: 20px;
                font-style: normal;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-weight: 700;
            }
        }
    }
    
    .mypurchases {
        .components_title {
            font-size: 20px;
            font-weight: bold;
            overflow: hidden;
            line-height: 28px;
            padding: 50px 0 20px;
            button .back {
                border: none;
                background: none;
                outline: none;
                float: left;
                line-height: 1;
                cursor: pointer;
            }
        }
    }
    .getServicePlans {
        ul {
            width: 842px;
            margin: 30px auto 0;
            li {
                border-top: 1px solid #BFBFBF;
                position: relative;
                padding: 10px 30px 10px 20px;
                &:last-child {
                    border-bottom: 1px solid #bfbfbf;
                }
                h4 {
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                p {
                    color: #BFBFBF;
                    font-size: 14px;
                    line-height: 1.5;
                    margin: 0;
                }
                .aside {
                    position: absolute;
                    right: 20px;
                    bottom: 10px;
                    display: flex;
                }
                h5 {
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 0;
                    line-height: 20px;
                    color: #000;
                    font-weight: bold;
                }
            }
        }
    }
    .anticon-question-circle {
        display: block !important;
        font-size: 20px;
        color: rgba(0,0,0,.2) !important;
    }
    .servicePop {
        background: rgba(0,0,0,0.5);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10;
        top: 0;
        left: 0;
        animation-name: am-fade;
        animation-duration: 0.2s;
        animation-timing-function: linear;
        .pop_content {
            width: 485px;
            border-radius: 30px;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 11;
            padding: 50px 30px 30px;
            animation-name: am-fade;
            animation-duration: 0.5s;
            animation-timing-function: linear;
        }
    }
    h3 {
        font-weight: bold;
        font-size: 22px;
        color: #000;
        text-align: center;
        margin-bottom: 30px;
    }
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #aaa;
        font-size: 20px;
        line-height: 2;
        margin-bottom: 15px;
    }
    @include mobileStyle {
        .components_title {
            text-align: center;
            font-size: 0.4rem !important;
            font-weight: normal !important;
            display: flex;
            border-bottom: 1px solid #ddd;
            position: sticky;
            top: 0;
            justify-content: space-evenly;
            height: 1.28rem;
            line-height: 1.28rem;
            margin-bottom: 0;
            background: white;
            z-index: 1;
            padding: unset;
            .back{
                height: 1.28rem;
                width: 1rem;
                text-align: center;
                position: absolute;
                left: 0px;
                z-index: 3;
                line-height: 1.15;
                .iconfont {
                    font-family: "iconfont" !important;
                    font-size: 16px;
                    font-style: normal;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-weight: normal;
                }
            }
            span {
                flex: 1 1 auto;
                justify-content: center;
                color: #000;
            }
        }
        .getServicePlans {
            ul {
                width: 100%;
                margin: auto;
                li {
                    padding: 0.2667rem;
                    border-bottom: 1px solid #cecece;
                    border-top: unset;
                    h4 {
                        font-size: 0.4rem;
                        line-height: 1;
                        margin-bottom: 0.1333rem;
                    }
                    p {
                        font-size: 0.34rem;
                        line-height: 1.25;
                        color: #555;
                    }
                }
            }
        }
        .servicePop {
            .pop_content {
                width: 95%;
                padding: .6667rem .4667rem .5333rem;
                border-radius: .4rem;
            }
        }
        h3 {
            font-size: .48rem;
            margin-bottom: 15px;
        }
        .item {
            font-size: .48rem;
            margin-bottom: unset;
            color: #555;
        }
        .list{
            .signInItem{
                .date{
                    color: #000;
                    background: #f2f2f2;
                    padding: 0 0.4rem;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    font-weight: normal;
                    font-size: .32rem;
                }
                ul{
                    margin-bottom: 0;
                    .class_detail_wrapper {
                        position: relative;
                    }
                    .class_detail_overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
