@import "../../scss/mixins.scss";

.modal-wrapper {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    display: none;

    &.show {
        transform-origin: center center;
        animation: open .5s;
        animation-fill-mode: forwards;
        display: block;
    }

    &.close {
        display: block;
        transform-origin: center center;
        animation: hide .5s;
        animation-fill-mode: forwards;
    }

    .modal_content {
        width: 100%;
        height: 100%;
        padding: 0 0.2667rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @include desktopStyle {
            width: 50%;
            margin: auto;

            p {
                margin-bottom: 0;
            }
        }

        .title,
        .times {
            text-align: center;
            font-size: 0.48rem;
            font-weight: bold;
        }

        .closeContent {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }
    }
}

@keyframes open {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes hide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}