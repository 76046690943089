.date_list {
  height: 1.81rem;
  background: #fff;
  z-index: 2;
  box-shadow: 0 1px 0 0 #f2f2f2;
  position: relative;
  padding: 0 0.6rem 0 0.6rem;
}
.date_list.pr .weekToggle {
  right: 0rem;
  justify-content: flex-start;
  border: 0px;
}
.date_list.pl .weekToggle {
  left: 0rem;
  justify-content: center;
  border: 0px;
}
.date_list .list {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  text-align: center;
}
.date_list .list a {
  height: 1.81rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #999;
  position: relative;
}
.date_list .list a::after {
  content: '';
  width: 1.0667rem;
  height: 0.2133rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s;
}
.date_list .list a .week {
  font-size: 0.266667rem;
}
.date_list .list a .num {
  font-size: 0.6rem;
  font-weight: bold;
}
.date_list .list a.active::after {
  background: #404040;
}
.date_list .list a.active .num {
  font-weight: bold;
  color: #000;
}
.date_list .weekToggle {
  width: 0.666667rem;
  height: 1.81rem;
  position: absolute;
  top: 0;
  background: none;
  outline: none;
  color: #333;
  z-index: 2;
  transition: 0.5s;
  transform-origin: center center;
  display: flex;
  align-items: center;
}
.date_list .weekToggle .iconfont {
  font-size: 0.4rem;
}
.date_list .weekToggle.rotate {
  transform: rotate(-180deg);
}
