.cancelDetails{
    height: 100%;
    .tab{
        height: 1.28rem;
        display: flex;
        justify-content: center;
        box-shadow: 0 1px 0 0 #cecece;
        position: relative;
        z-index: 2;
        position: sticky;
        top: 0;
        background: white;
        .back{
            font-size: 0.3733rem;
            height: 1.28rem;
            width: 1rem;
            text-align: center;
            position: absolute;
            left: 0;
            background: none;
            z-index: 3;
        }
        .item{
            width: auto;
            padding: 0 8%;
            text-align: center;
            height: 100%;
            font-size: 0.3467rem;
            &.black span{color: #000;}
            span{
                line-height: 1.28rem;
                display: inline-block;
                position: relative;
                color: #bbb;
                transition: 0.5s;
                &:after{
                    content: '';
                    width: 100%;
                    height: 0.1333rem;
                    background: #404040;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    transition: 0.5s;
                    opacity: 0;
                }
            }
            &.active span{
                color: #000;
                &:after{opacity: 1;}
            }
        }
    }
    .cancelContent{
        overflow: scroll;
    }
    .item{
        display: block;
        li{
            display: flex;
            justify-content: space-between;
            font-size: 0.3467rem;
            color: #808080;
            border-bottom: 1px solid #f2f2f2;
            height: 1.2667rem;
            line-height: 1.2667rem;
            padding: 0 0.2667rem;
            &:last-child{
                border-bottom:none;
            }
            .name{
                flex-basis: 40%;
            }
            .info{
                flex-basis: 60%;
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    hr{
        height: 0.48rem;
        background: #f2f2f2;
        padding: 0;
        border:none;
    }
}