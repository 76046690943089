.pop_list_wra{
    position: relative;
    z-index: 99;

    &.silent {
        display: none;
    }

    .titile{
        font-size: 0.4267rem;
        font-weight: bold;
        color: #fff;
        line-height: 1;
        text-align: center;
    }
    .pop_content{
        background: #fff;
        overflow: hidden;
        border-radius: 0.2667rem;
        margin: 0.5333rem 0;
        transition: 0.5s;
        .panel{
            height: 1.3333rem;
            border-bottom: 1px solid #dedede;
            padding: 0 0.4rem;
            position: relative;
            display: flex;
            align-items: center;
            .name{
                font-size: 0.3733rem;
                color: #333;
                margin-right: 0.2rem;
            }
            .iconfont{
                position: absolute;
                right: 0.4rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0.48rem;
                color: #888;
            }
            &:last-child{
                border-bottom: none;
            }
        }
    }
    .opera{
        text-align: center;
        button{
            width: 2.6667rem;
            height: 0.96rem;
            line-height: 0.96rem;
            border-radius: 0.16rem;
            color: #888;
            font-size: 0.3733rem;
            background: #fff;
        }
    }
}