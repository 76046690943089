.class_list_wrapper{
    height: 2.16rem;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.22rem 0.4267rem 0.22rem 0.4267rem;
    &.black{
        background-color: #434343;
        .class_info{
            .classTitle{
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 5px;
                h4{
                    color: #fff;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 0.4rem;
                    line-height: 1;
                    max-width: 100%;
                }
                .class-name {
                    flex: 1;
                    -webkit-line-clamp: 2;
                    height: fit-content;
                    -webkit-line-clamp: 2 !important;
                    -webkit-box-orient: vertical !important;
                    display: -webkit-box !important;
                    white-space: normal !important;
                }
                .time{
                    float: right;
                    margin-left: 0.1rem;
                }
            }
            img{
                position: absolute;
                width: 16px;
                height: 16px;
                right: 0;
                top: 0;
            }
            .down{
                p{
                    color: #fff;
                }
            }
        }
    }
    .avatarSvg {
        width: 1.4667rem;
        height: 1.4667rem;
    }
    .avatar{
        width: 1.4667rem;
        height: 1.4667rem;
        // overflow: hidden;
        transform: scale(1.2);
        img{
            width: 100%;
            height: 100%;
        }
        .vaccinated{
            position: absolute;
            left: 0.75rem;
            top: 1rem;
            width: 0.5rem;
            height: 0.5rem;
        }
    }
    .line{
        width: 0.16rem;
        height: 1.4667rem;
        position: absolute;
        left: 0;
        top:50%;
        transform: translateY(-50%);
    }
    .class_info{
        width: calc(100% - 1.4667rem);
        padding: 0 0 0 0.2667rem;
        line-height: 1;
        // height: 1.4667rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 5px;
        .classTitle{
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 5px;
            h4{
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: bold;
                color: #000;
                font-size: 0.4rem;
                line-height: 1;
                max-width: 100%;
            }
            .class-name{
                flex: 1;
                -webkit-line-clamp: 2;
                height: fit-content;
                -webkit-line-clamp: 2 !important;
                -webkit-box-orient: vertical !important;
                display: -webkit-box !important;
                white-space: normal !important;
            }
            .time{
                float: right;
                margin-left: 0.1rem;
            }
        }
        .down{
            width: 100%;
            // height: 1.1rem;
            display: flex;
            justify-content: space-between;
            .left{
                width: calc(100% - 2.3rem);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: 5px;
                .leftInfo {
                    display: flex;
                    .infoContent{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    .teacherName{
                        // max-width: 50%;
                        width: fit-content;
                    }
                    .timeContent{
                        white-space: nowrap;
                        // width: 1.1rem;
                    }
                    .mood{
                        display: flex;
                        // max-width: 25%;
                        .moodName{
                            font-weight: bold;
                        }
                    }
                }
            }
            p{
                color: #888;
                font-size: 0.32rem;
                .iconfont{font-size: 0.24rem;}
                margin-bottom: 0rem;
            }
            .opera{
                width: 2.04rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}