
@import "../../../scss/mixins";
.schedule_button_wrapper{
    @include mobileStyle{
        .bookBtn {
        border: none;
        }
        .btn{
            width: 2rem;
            height: 0.8rem;
            line-height: 0.8rem;
            font-size: 0.3rem;
            border-radius: 0.1333rem;
            text-align: center;
            transition: 0.5s;
            padding: 0;
            font-weight: 700;
            color: #888888;
            &.btn1,&.btn3,&.btn17{
                border-color: #aaa; background: #F0EFF5;
                &.Y{
                    &.confirm{ background: #C4E7FF;}
                }
                &.F{
                    &.confirm{ background: #FFCED0;}
                }
            }
            &.btn2,&.btn15,&.btn14{
                color: #fff;
                &.Y{
                    background: #14E1DC;border-color:#14E1DC;
                    &.confirm{ background: #000000;}
                }
                &.F{
                    background: #F00F64;border-color:#F00F64;
                    &.confirm{ background: #000000;}
                }
            }
            &.btn4{color: #fff; background: #000;}
            // &.btn5{border-color: #ED1C24; color: #fff; background: #ED1C24;}
            &.btn5, &.btn12{color: #fff; background: #BFBFBF;}
            &.btn13, &.btn8, &.btn11{background: #EFEFF4;}
            &.btn7, &.btn9{background-color: #EFEFEF;}
            &.btn16 {
                background: #EFEFF4;
                white-space: unset !important;
                padding: 0 15px;
                line-height: 0.3rem !important;
            }
        }

        &.black{
            .btn{
                font-weight: 700;
                color: #888888;
                &.btn1,&.btn3,&.btn17{
                    color: #888888; background: rgba(255, 255, 255, 0.76);
                    &.Y{
                        &.confirm{ background: #59290B;border-color:#59290B;color: #E06B26; }
                    }
                    &.F{
                        &.confirm{ background: #59290B;border-color:#59290B;color: #E06B26; }
                    }
                }
                &.btn2,&.btn15,&.btn14{
                    color: #fff; background: #D17239;
                    &.Y{
                        background: #D17239;border-color:#D17239;
                        &.confirm{ background: #59290B;border-color:#59290B;color: #E06B26; }
                    }
                    &.F{
                        background: #D17239;border-color:#D17239;
                        &.confirm{ background: #59290B;border-color:#59290B;color: #E06B26; }
                    }
                }
                &.btn5, &.btn9, &.btn12{ background: rgba(255, 255, 255, 0.5); color: #fff;}
                &.btn13, &.btn8, &.btn11{background: rgba(255, 255, 255, 0.76);}
                &.btn4{background: #D17239; color: #fff;}
                &.btn7{background-color: #EFEFEF;}
                &.btn16{background: rgba(255, 255, 255, 0.76); white-space: unset !important; padding: 0 15px; line-height: 0.3rem !important;}
            }
        }
    }


    @include desktopStyle{
        .bookBtn {
            min-width: 78px;
            }
        .btn{
                border: 0;
            }
            .status2,.status15,.status14{
                color: #fff;
                &.Y{
                    background: #14E1DC;border-color:#14E1DC;
                    &.confirm{
                        background: #000000;
                        &:hover{
                        color: #fff;
                    }}
                }
                &.F{
                    background: #F00F64;border-color:#F00F64;
                    &.confirm{
                        background: #000000;
                        &:hover{
                        color: #fff;
                        }}
                }
            }
            .status1,.status3,.status17{
                border-color: #aaa; background: #F0EFF5;
                &.Y{
                    &.confirm{ background: #C4E7FF;}
                }
                &.F{
                    &.confirm{ background: #FFCED0;}
                }
            }
            .status4{color: #fff; background: #000;}
            .status5{color: #fff; background: #BFBFBF;}
            .status13, .status8, .status11, .status16{background: #EFEFF4;}
            .status7, .status9{background-color: #EFEFEF;color: #000;}
    }

}